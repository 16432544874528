.results_page_container .subtitle {
    margin-top: 48px;
    margin-bottom: 0px;
}

.results_page_container ul.table_list_wrapper {
    border: none;
    box-shadow: none;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    cursor: pointer;
}

.results_page_container ul div h3 {
   margin-top: 0px;
}

.result_item {
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 0.8px solid var(--soft-gray);
}

.result_item:last-of-type {
    padding-bottom: 80px;
    border-bottom: none;
}

.article_breadcrumb {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--ligth-gray);
    margin-bottom: 24px;
}

.article_preview {
    font-family: var(--font-family);
    margin-bottom: 0px;
}

.not_found_category_wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.not_found_page ul.custom_list {
    margin-top: 0px !important;
}

h2.not_found_category {
    margin-top: 48px;
}