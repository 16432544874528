.input-group-btn .btn {
    border-top: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}

.input-group-btn .btn .fa {
    color: #f39200;
}

.select-col {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .select-col {
        transition: none;
    }
}

.select-col:focus {
    border-color: #80b4da;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(1, 105, 181, 0.25);
}

.select-col[multiple],
.select-col[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none;
}

.select-col:disabled {
    background-color: #e9ecef;
}

.select-col:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529;
}

.select-col-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
}

.select-col-lg {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
}

.form-floating>.form-control,
.form-floating>.select-col {
    height: calc(3.5rem + 2px);
    line-height: 1.25;
}

.form-floating>.select-col {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.select-col~label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group>.form-control,
.input-group>.select-col {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.select-col:focus {
    z-index: 3;
}

.input-group-lg>.form-control,
.input-group-lg>.select-col,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
}

.input-group-sm>.form-control,
.input-group-sm>.select-col,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
}

.input-group-lg>.select-col,
.input-group-sm>.select-col {
    padding-right: 3rem;
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .select-col:valid,
.input-group .select-col.is-valid {
    z-index: 1;
}

.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .select-col:valid:focus,
.input-group .select-col.is-valid:focus {
    z-index: 3;
}

.was-validated .select-col:invalid,
.select-col.is-invalid {
    border-color: #DC143C;
}

.was-validated .select-col:invalid:not([multiple]):not([size]),
.was-validated .select-col:invalid:not([multiple])[size="1"],
.select-col.is-invalid:not([multiple]):not([size]),
.select-col.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23DC143C'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23DC143C' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .select-col:invalid:focus,
.select-col.is-invalid:focus {
    border-color: #DC143C;
    box-shadow: 0 0 0 0.25rem rgba(220, 20, 60, 0.25);
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .select-col:invalid,
.input-group .select-col.is-invalid {
    z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .select-col:invalid:focus,
.input-group .select-col.is-invalid:focus {
    z-index: 3;
}

.select-col,
.form-control {
    font-size: 14px;
}

.select-col {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23f39200' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

a .fa {
    color: #f39200;
}

a .fa:hover {
    color: #f39200;
}

form .fa {
    color: #f39200;
}