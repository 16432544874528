.feedback_container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  max-width: 722px;
  width: 100%;
  border-top: 1px solid var(--soft-gray);
  padding: 24px 32px 24px 32px;
  position: relative;
}

.feedback_container.no_padding {
  padding: 24px 0px;
}

.feedback_container p.feedback_title {
  margin-bottom: 24px;
}

.feedback_container button {
  height: 40px;
  width: 56px;
  border-radius: 8px;
  padding: 11px 9px 11px 9px;
  cursor: pointer;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.feedback_container button svg {
  height: 16px;
  width: 16px;
  margin-left: 5px;
}

.feedback_container .positive_feedback_button {
  border: 1px solid var(--notification-dark-green);
  color: var(--notification-dark-green);
  margin-right: 16px;
}

.feedback_container .positive_feedback_button:hover {
  background-color: var(--notification-light-green);
  border: none;
}

.feedback_container .negtive_feedback_button {
  border: 1px solid var(--notification-dark-red);
  color: var(--notification-dark-red);
}

.feedback_container .negtive_feedback_button:hover {
  background-color: var(--notification-light-red);
  border: none;
}

.feedback_container .comment_box div {
  width: 520px;
  height: 258px;
  max-height: 258px;
  align-items: baseline;
  border-radius: 8px;
  border: 0.8px solid var(--ligth-gray) !important;
}

.comment_box div.active {
  border: 0.8px solid var(--background-blue) !important;
  outline: 'none'
}

.feedback_container .comment_box div textarea {
  height: 80% !important;
  overflow: visible !important;
  padding: 24px 16px 0px 16px;
}

.feedback_container .comment_box div textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--ligth-gray);
  opacity: 1;
}

.feedback_container .comment_box div textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--ligth-gray);
}

.comment_box_wrapper {
  position: relative;
}

.comment_box_wrapper span.counter {
  position: absolute;
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--soft-gray);
  top: 312px;
  left: 465px;
}
.comment_box_wrapper span.counter.negative {
  top: 268px;
}

.comment_box_wrapper .send_button_wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 16px;
}

.comment_box_wrapper .loading_text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--notification-dark-red);
}

p.toast_action {
  color: var(--main-blue);
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  text-transform: none;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
}

.end_feedback .MuiSnackbar-root {
  top: 50px;
}

@media screen and (min-width: 320px) and (max-width: 720px) {
  .feedback_container {
    padding: 16px 16px 16px 8px;
  }

  .feedback_container.no_padding p.feedback_title {
    width: 100%;
    text-align: center;
  }
  .feedback_container.no_padding p {
    text-align: center;
  }
  
  .feedback_container p.feedback_title {
    width: 50%;
  }

  .feedback_container .comment_box div {
    width: 100%;
    max-height: 200px;
  }

  .feedback_container .comment_box div textarea {
    padding-top: 16px;
  }

  .comment_box_wrapper span.counter {
    top: 251px;
    left: 277px;
  }

  .comment_box_wrapper span.counter.negative {
    top: 210px;
    left: 290px;
  }

  .comment_box_wrapper {
    width: 100%;
  }

}