.table-box-container {
    border: 1px solid rgba(0, 0, 0, 10%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
}

.table-head {
    font-weight: 700 !important;
    background: transparent !important;
    color: #777777;
    border-bottom: 1px solid #f5f5f5;
    font-size: 14px;
    text-transform: capitalize !important;
}

.table-content {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.edit-btn {
    display: flex;
    justify-content: center;
    font-size: 17px;
    color: #f39200;
    cursor: pointer;

}

.new-btn {
    font-size: 18px;
    color: #0169b5;
    cursor: pointer;
}

.new-icon {
    color: #f39200;
}

.modal-title {
    font-size: 17px;
    color: #0169b5;
}

.button-container {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    justify-content: center;
    width: inherit;
}

.save-button {
    color: #fff;
    border-color: #f39200;
    background: #f59300;
    background: linear-gradient(90deg, #f59300, #dd494a);
    width: 7.6rem;
    line-height: 1.5;
    border-radius: 5px;
    padding: 6px;
    border: 1px solid transparent;
    -webkit-appearance: button;
    appearance: button;
    opacity: 1;
}

.save-button-load {
    opacity: 0.8;
}

.employee-list {
    position: relative;
    width: 100%;
}

.autocomplete-results {
    position: absolute;
    z-index: 999;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
}

.employee-item {
    padding: 10px;
    cursor: pointer;
}

.employee-item:hover {
    background-color: rgba(109, 105, 107, 0.8);
    color: #fff;
}

.employee-item.selected {
    background-color: #007bff;
    color: #fff;
}

.search-spinner {
    text-align: center;
}

.status {
    text-transform: uppercase;
    font-weight: 700;
}

.valid {
    color: #0169b5;
}

.invalid {
    color: #dd494a;
}

.invalidation-disabled {
    color: #777777;
    cursor: not-allowed;
}