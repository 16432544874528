.dataNumber {
    position: absolute;
    bottom: 20px;
    left: 30px;
    font-size: 26px;
}

.spinnerDataLoad {
    position: absolute;
    bottom: 20px;
    left: 30px;
}

.volver {
    float: right !important;
    margin-right: 20px;
    text-decoration: solid;
    margin-bottom: 30px;
}