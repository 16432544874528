/* organigrama-ss.module.css */
.organigrama-container {
    max-width: inherit;
    overflow: auto;
    padding: 10px;
}

.hierarchy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* right: 20%; */
    /* position: relative; */
}

.boss-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.subordinate-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    /* flex-direction: row; */
    /* top: -2px;
    position: relative;
    justify-content: center; */
}

.children-connector-container,
.root-connector-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.root-connector-container {
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-spacing {
    display: flex;
    justify-content: center;
}

.root-connector {
    width: 2px;
    height: 100%;
    background-color: #3375CC;
}

.middle-child-connector-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
}

.middle-child-connector-top {
    width: 300px !important;
    height: 1px !important;
    background-color: #3375CC;
}

.middle-child-connector-down {
    width: 2px !important;
    height: 11px !important;
    background-color: #3375CC;
}

.first-child-connector {
    width: 160px;
    height: 12px !important;
    border-top-left-radius: 10px !important;
    border-left: 2px solid #3375CC;
    border-top: 2px solid #3375CC;
}

.last-child-connector {
    width: 160px;
    height: 12px !important;
    border-top-right-radius: 10px !important;
    border-right: 2px solid #3375CC;
    border-top: 2px solid #3375CC;
    bottom: 2px;
}


.nodecontent {
    font-family: 'Fira Sans', sans-serif;
    display: flex;
    flex-direction: column;
    background-color: none;
    border-radius: 4px;
    color: white;
    width: 300px;
    margin-left: 10px;
}


.node-card-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
    font-weight: bold;
    margin-bottom: 0px;
    height: 30px;
    font-size: 13px;
    /* cursor: pointer; */
    color: #fff !important;
    align-items: center !important;
    text-transform: uppercase !important;
    background-color: #3375CC !important;
}


.info-icon {
    font-size: 20px !important;
}

.organigrama-card {
    color: #000000 !important;
    transition: opacity 600ms, visibility 600ms;
    height: 145px !important;
    border: 1px solid rgba(0, 0, 0, 10%);
    border-radius: 0.25rem;
    width: 100% !important;
    box-sizing: border-box;
}

.organigrama-card-container {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    padding: 0.25rem 1.25rem 0.25rem 1.25rem;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 10%);
    height: 90px;
}

.organigrama-card-container-under {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    padding: 0.25rem 1.25rem 0.25rem 1.25rem;
    align-items: center;
    height: 55px;
}

.card-reportes {
    text-transform: uppercase;
    color: #344563;
    font-size: 12px;
    font-weight: 400;
}

.report-elements {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    align-items: center;
}

.content-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-text {
    color: #3375CC !important;
    font-weight: bold !important;
    -webkit-font-smoothing: antialiased !important;
    text-transform: capitalize;
    margin-bottom: 5px;
}

.area {
    text-transform: uppercase;
    color: #6B778C;
    font-size: 10px;
    font-weight: 500;
}

.employee-image {
    height: 50px;
    width: 50px;
    float: left;
    background-color: #CCDCF3;
    border-radius: 100px;
    margin-right: 15px;
}

.card-reportes {
    margin-bottom: 3px;
    text-transform: uppercase;
    color: #344563;
    font-size: 12px;
    font-weight: 400;
}

.internal-data {
    width: auto;
    margin-right: 10px;
    display: -webkit-inline-box;
    color: #344563;
}

.external-data,
.vacancy-data {
    width: auto;
    margin-right: 10px;
    margin-left: 10px;
    display: -webkit-inline-box;
    color: #344563;
}

.pais {
    float: right;
    margin-top: 2px;
    cursor: pointer;
}

.short-name {
    text-align: center;
    margin-top: 14px;
    color: #0053C0;
    font-weight: 500;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeLine {
    from {
        opacity: 0;
        height: 0;
    }

    to {
        opacity: 1;
        height: 12px;
    }
}

@keyframes fadeLine4 {
    from {
        opacity: 0;
        height: 0;
    }

    to {
        opacity: 1;
        height: 11px;
    }
}

@keyframes fadeLine2 {
    from {
        opacity: 0;
        width: 0;
    }

    to {
        opacity: 1;
        width: 300px;
    }
}

@keyframes fadeLine3 {
    from {
        opacity: 0;
        width: 0;
    }

    to {
        opacity: 1;
        width: 160px;
    }
}

.nodecontent {
    animation: fadeIn 0.7s ease-in-out;
    animation-fill-mode: both;
}

.root-connector {
    animation: fadeLine 1.2s ease-in-out;
    animation-fill-mode: both;
}

.middle-child-connector-top {
    animation: fadeLine2 1.3s ease-in-out;
    animation-fill-mode: both;
}

.last-child-connector,
.first-child-connector {
    animation: fadeLine3 1.4s ease-in-out;
    animation-fill-mode: both;
}

.middle-child-connector-down {
    animation: fadeLine4 1.5s ease-in-out;
    animation-fill-mode: both;
}

.title-input {
    text-transform: uppercase;
    font-family: "Fira Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    font-size: 12px;
    color: #0053C0;
    padding: 0 4px;
}

.input-content {
    font-family: "Fira Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: 14px;
    color: #42526E;
    padding: 6px 4px;
    height: 38px;
    line-height: 2;
}

.ico-external,
.ico-internal,
.ico-vacancy {
    cursor: pointer;
}