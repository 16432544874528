.all_categories_container {
  min-width: 720px;
}

.all_categories_title {
  margin-top: 0px;
}

div.all_categories_card_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  justify-content: space-between;
}

div.all_categories_card {
  width: 48%;
  min-width: 300px;
  height: 252px;
  margin-bottom: 24px;
}

div.all_categories_card_content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}

.all_categories_card_icon {
  width: 70px;
  height: 70px;
  margin-bottom: 8px;
  margin-top: 5px;
}

.all_categories_card_text_wrapper {
  text-align: center;
  min-height: 100px;
  max-height: 100px;
}

.all_categories_card_text_wrapper h4 {
  font-family: var(--font-family);
  color: var(--main-blue);
  font-weight: 600;
  font-size: 22px;
}

.all_categories_card_text_wrapper p {
  font-family: var(--font-family);
  color: var(--dark-gray);
  font-weight: 400;
  font-size: 14px;
  padding-left: 18px;
  padding-right: 18px;
}

div.all_categories_card_button_wrapper {
  width: 113%;
  display: flex;
  justify-content: flex-end;
}

button.all_categories_card_button {
  padding-right: 0px;
}

.all_categories_card_button svg {
  color: var(--main-orange);
  width: 20px;
  height: 20px;
}

/* mobile */
@media screen and (max-width: 415px) {

  div.all_categories_card_button_wrapper {
    width: 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 720px) {
  .all_categories_container {
    min-width: 400px;
  }

  div.all_categories_card_wrapper {
    flex-direction: column;
  }

  div.all_categories_card_content_wrapper {
    flex-direction: row;
    align-items: baseline;
  }

  div.all_categories_card {
    width: 100%;
    min-width: 340px;
    height: 80px;
    margin-bottom: 24px;
  }

  .all_categories_card_icon {
    width: 30px;
    height: 30px;
  }

  .all_categories_card_text_wrapper {
    position: relative;
    top: -4px;
  }

  .all_categories_card_text_wrapper h4 {
    font-size: 16px;
    width: 240px;
    text-align: left;
    padding-left: 8px;
  }

  .all_categories_card_text_wrapper p {
    display: none;
  }

}