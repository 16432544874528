.collapse_list {
  display: block;
}
.collapse_list li.collapse_list_item:hover {
  background-color: white !important;
  color: var(--secondary-blue);
}
.collapse_list li.collapse_list_item .collapse_list_item_title:hover {
  color: var(--secondary-blue);
}

.collapse_list .collapse_list_item_button svg {
  color: var(--secondary-blue);
  width: 32px;
  height: 18px;
}

.collapse_list .collapse_list_item_button {
  padding-right: 12px;
}

.collapse_list li.collapse_list_item {
  padding-left: 24px;
}

.collapse_list li.collapse_list_item .collapse_list_item_title {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--dark-gray);
  margin-bottom: 0px;
}

.collapse_list li.collapse_list_item .collapse_list_item_subtitle {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--dark-gray);
  margin-top: 4px;
  margin-bottom: 0px;
  letter-spacing: 0.2px;
}
.collapse_list li.collapse_list_item .collapse_list_item_subtitle svg{
  width: 8px;
  height: 4px;
  margin: 3px;
}
div.collapse_button_wrapper {
  margin-top: 24px;
}