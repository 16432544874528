nav.breadcrumb_root {
  /* root nav ol  */
}

a.breadcrumb_item {
  line-height: 12px;
  font-size: 10px;
  text-align: left;
  font-family: var(--font-family);
  color: var(--ligth-gray);
  font-weight: 500;
  text-decoration: none;
  margin-right: 5px;
  margin-left: 5px;
}

a.breadcrumb_item:hover {
  text-decoration: underline;
}

.breadcrumb_item.active {
  color: var(--soft-gray);
}

.breadcrumb_item.active:hover {
  text-decoration: none;
}

a.breadcrumb_item i {
  margin-right: 2px;
  color: var(--ligth-gray);
}

button.breadcrumb_collapse {
  color: var(--ligth-gray)
}

button.breadcrumb_collapse svg {
  color: var(--main-blue);
  height: 20px;
}

div.popper_menu_arrow {
  position: fixed;
  background-color: transparent;
  z-index: -1;
}
div.popper_menu_arrow2 {
  position: fixed;
  z-index: 3;
}

div.popper_menu_arrow::before {
    background-color: white;
    content: '';
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: -8px;
    left: 12px;
    z-index: -1;
    transform: rotate(45deg);
    box-shadow: 0px 2px 9px rgba(226, 228, 233, 0.95);
}
div.popper_menu_arrow2::before {
    background-color: white;
    content: '';
    display: block;
    position: absolute;
    width: 17px;
    height: 13px;
    top: -6px;
    left: 14px;
    transform: rotate(45deg);
}