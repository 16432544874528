div.category_card {
    width: 100%;
    border-color: var(--secondary-blue);
    border-radius: 16px;
    padding: 22px;
    margin-bottom: 13px;
    cursor: pointer;
}
div.category_card:hover {
    box-shadow: 0px 8px 10px rgba(213, 213, 214, 0.5);
}

div.card_content_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
div.card_content_wrapper:last-child {
    padding: 0px;
}

.card_icon {
    width: 36px;
    margin-right: 15px;
}

.card_text_wrapper {
    padding-top: 8px;
    width: 80%;
}

h4.card_title {
    font-family: var(--font-family);
    color: var(--main-blue);
    font-weight: 600;
    font-size: 14px;
}
p.card_content {
    font-family: var(--font-family);
    color: var(--dark-gray);
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.1px;
}

button.card_button {
    padding-left: 80px;
}

button.card_button:hover {
    background-color: transparent;
}

button.card_button svg {
    color: var(--main-orange);
    width: 32px;
    height: 32px;
}
/* mobile */
@media screen 
  and (min-width: 320px) 
  and (max-width: 720px)  { 

    div.category_card {
        width: 100%;
        border-radius: 8px;
    }

    div.card_content_wrapper:last-child {
        padding: 0px;
    }

    .card_icon {
        width: 40px;
        margin-bottom: 15px;
    }

    button.card_button {
        display: flex;
        width: 20%;
        padding-left: 0px;
        justify-content: flex-end;
    }

    button.card_button svg {
        width: 20px;
        height: 20px;
    }
}

/* tablet */
@media screen 
  and (min-width: 721px) 
  and (max-width: 990px)  { 
    .card_icon {
        margin-right: 20px;
    }
    button.card_button {
        padding-left: 30px;
    }
}
/* desktop */
@media screen 
  and (min-width: 991px) 
  and (max-width: 1300px)  { 
    .card_icon {
        margin-right: 15px;
    }
    
}
/* desktop XL*/

@media screen 
  and (min-width: 1401px)  
  and (max-width: 1640px) { 
    .card_icon {
        margin-right: 10px;
    }
}

@media screen 
  and (min-width: 2000px)  { 

    .card_icon {
        margin-right: 20px;
    }

}
