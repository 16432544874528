/* Ninebox */

.ninebox {
    border: 3px solid;
    border-radius: 8px;
    width: 100%;
}

.ninebox_content {
    position: relative;
    width: 100%;
}

.ninebox__header {
    background: #eeeeee;
    padding: 5px 10px;
    border-radius: 3px 3px 0 0;
}

.ninebox__number {
    color: #333;
    display: inline-block;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    min-width: 30px;
    background: #ffffff;
    text-align: center;
    padding: 1px 0;
    font-size: 20px;
    margin-right: 10px;
}

.ninebox__title {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.ninebox__info .btn {
    padding: 3px;
}

.ninebox__info .btn .fa {
    color: #0169B5;
}

.ninebox__body {
    padding: 15px 10px;
    position: relative;
    overflow-y: auto;
    height: 230px;
}

.ninebox__user {
    margin-bottom: 5px;
}

.ninebox__user_img {
    border: 2px solid #44aea3;
    border-radius: 30px;
    padding: 1px;
    margin-right: 5px;
}

.ninebox__user_name {
    padding: 5px 10px;
    border: 2px solid #6341ff;
    border-radius: 10px;
    color: black
}

.ninebox_yellow {
    border-color: #fbbf3f;
}

.ninebox_yellow .ninebox__header {
    background: #fbbf3f;
}

.ninebox_yellow .ninebox__number {
    color: #fbbf3f;
}

.ninebox_yellow_light {
    border-color: #edc950;
}

.ninebox_yellow_light .ninebox__header {
    background: #edc950;
}

.ninebox_yellow_light .ninebox__number {
    color: #edc950;
}

.ninebox_orange {
    border-color: #eb822d;
}

.ninebox_orange .ninebox__header {
    background: #eb822d;
}

.ninebox_orange .ninebox__number {
    color: #eb822d;
}

.ninebox_green {
    border-color: #44aea3;
}

.ninebox_green .ninebox__header {
    background: #44aea3;
}

.ninebox_green .ninebox__number {
    color: #44aea3;
}

.ninebox_blue {
    border-color: #0169B5;
}

.ninebox_blue .ninebox__header {
    background: #0169B5;
}

.ninebox_blue .ninebox__number {
    color: #0169B5;
}

.ninebox_blue .ninebox__title {
    color: #ffffff;
}

.ninebox_blue .ninebox__info .fa {
    color: #ffffff;
}

.ninebox_red {
    border-color: #ab111e;
}

.ninebox_red .ninebox__header {
    background: #ab111e;
}

.ninebox_red .ninebox__number {
    color: #ab111e;
}

.ninebox_red .ninebox__title {
    color: #ffffff;
}

.ninebox_red .ninebox__info .fa {
    color: #ffffff;
}

.ninebox_data {
    font-size: 22px;
    color: #333;
    text-align: center;
    border: 3px solid;
    border-radius: 8px;
    padding: 3px 0;
    width: 100%;
    position: relative;
}

.ninebox_data_red {
    border-color: #ab111e;
}

.ninebox_data_orange {
    border-color: #eb822d;
}

.ninebox_data_green {
    border-color: #44aea3;
}

.ninebox_data_blue {
    border-color: #0169B5;
}

.ninebox_data_title {
    border: 0;
    font-size: 30px;
}

.ninebox_data_title .fa {
    font-size: 22px;
    color: #0169B5;
}

.ninebox_data_rotate {
    transform: rotate(-90deg);
    display: block;
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 20px);
    text-align: center;
    right: calc(50% - 40px);
    bottom: calc(50% - 15px);
    white-space: nowrap;
}

.ninebox_data_vertical {
    height: 31%;
    width: 50px;
    margin-bottom: 24px;
}

.ninebox .btn_outline_primary {
    border: 2px solid #6341ff;
    color: #6341ff;
}

.ninebox .btn_outline_primary:hover {
    background-color: #6341ff;
    color: #ffffff
}

.ninebox_modal {
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #ffffff;
    overflow-x: auto;
    border: 1px solid #9d9d9c;
    opacity: 0;
    z-index: -999;
}

.ninebox_modal__header {
    text-align: right;
    margin: 10px 10px 0 0;
}

.ninebox_modal__header .btn {
    background-color: #eeeeee;
    border-radius: 50px;
}

.ninebox_modal__header .btn .fa {
    color: #0169B5;
}

.ninebox_modal__body {
    line-height: 1.4;
    padding: 0 10px 10px 10px;
}

.ninebox_modal__body h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.ninebox_modal__body .fa {
    margin: 10px 0;
    color: #0169B5;
}

.ninebox_modal .show {
    display: block;
    z-index: 1;
    opacity: 1;
}

.ninebox_modal_readmore {
    position: absolute;
    background-color: #ffffff;
    top: 0;
    height: 100%;
    z-index: -9999;
    opacity: 0;
    display: none;
}

.ninebox_modal_readmore .btn_close_ninebox_modal_readmore {
    background-color: #eeeeee;
    border-radius: 50px;
    padding: 0.2rem 0.5rem;
}

.ninebox_modal_readmore .btn_close_ninebox_modal_readmore .fa {
    color: #0169B5;
}

.ninebox_modal_readmore .ninebox__body {
    height: calc(100% - 40px);
}

.ninebox_modal_readmore.show {
    z-index: 2;
    opacity: 1;
    display: block;
}

.ninebox_arrow_vertical {
    background-image: url(../images/arrow-vertical.svg);
    width: 70px;
    background-repeat: no-repeat;
    background-position: top center;
}

.ninebox_arrow_horizontal {
    background-image: url(../images/arrow-horizontal.svg);
    height: 40px;
    background-repeat: no-repeat;
    background-position: center right;
}

.accordion_item {
    background-color: #ffffff;
    border: 1px solid rgb(0, 0, 0, .125)
}


.accordion_header {
    margin-bottom: 0;
}

.accordion_item {
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion_ninebox .accordion_item {
    border: 0;
}

.accordion_button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #ffffff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}


.accordion_button[aria-expanded=true]::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
}

.accordion_button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
    color: red
}

.accordion_header,
.accordion_header .accordion_button {
    font-size: 14px;
}


.accordion_button::after {
    position: absolute;
    right: 20px;
    top: calc(50% - 10px);
    color: #ffffff;
}

.accordion .accordion_button[aria-expanded=true] {
    background-color: #0169B5;
    color: #ffffff;
}

.accordion_ninebox .accordion_button[aria-expanded=true] {
    border-radius: 8px 8px 0 0px;
}

.accordion_ninebox .accordion_button .ninebox__number {
    color: #0169B5;
}

.accordion_ninebox .accordion_button.collapsed {
    margin-top: 20px;
}

.accordion_ninebox .accordion_button {
    display: block;
    border-radius: 8px;
    margin-top: 20px;
}

.accordion .accordion_button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion .accordion_button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion_ninebox .ninebox__body {
    height: auto;
    border-right: 3px solid #0169B5;
    border-bottom: 3px solid #0169B5;
    border-left: 3px solid #0169B5;
}

.accordion_ninebox .accordion_button {
    display: block;
    border-radius: 8px;
    margin-top: 20px;
}

.accordion_ninebox .accordion_button.collapsed {
    margin-top: 20px;
}

.accordion_ninebox .accordion_button[aria-expanded=true] {
    border-radius: 8px 8px 0 0px;
}

.accordion_ninebox .accordion_button .ninebox__number {
    color: #0169B5;
}

.accordion_ninebox .accordion_item {
    border: 0;
}

.accordion_ninebox .accordion_item_ninebox_blue .accordion_button {
    background-color: #0169B5;
    color: #ffffff;
}

.accordion_ninebox .accordion_item_ninebox_blue .ninebox__number {
    color: #0169B5;
}

.accordion_ninebox .accordion_item_ninebox_blue .ninebox__body {
    border-color: #0169B5;
}

.accordion_ninebox .accordion_item_ninebox_green .accordion_button {
    background-color: #44aea3;
    color: #333;
}

.accordion_ninebox .accordion_item_ninebox_green .ninebox__number {
    color: #44aea3;
}

.accordion_ninebox .accordion_item_ninebox_green .ninebox__body {
    border-color: #44aea3;
}

.accordion_ninebox .accordion_item_ninebox_yellow .accordion_button {
    background-color: #fbbf3f;
    color: #333;
}

.accordion_ninebox .accordion_item_ninebox_yellow .ninebox__number {
    color: #fbbf3f;
}

.accordion_ninebox .accordion_item_ninebox_yellow .ninebox__body {
    border-color: #fbbf3f;
}

.accordion_ninebox .accordion_item_ninebox_yellow_light .accordion_button {
    background-color: #edc950;
    color: #333;
}

.accordion_ninebox .accordion_item_ninebox_yellow_light .ninebox__number {
    color: #edc950;
}

.accordion_ninebox .accordion_item_ninebox_yellow_light .ninebox__body {
    border-color: #edc950;
}

.accordion_ninebox .accordion_item_ninebox_orange .accordion_button {
    background-color: #eb822d;
    color: #333;
}

.accordion_ninebox .accordion_item_ninebox_orange .ninebox__number {
    color: #eb822d;
}

.accordion_ninebox .accordion_item_ninebox_orange .ninebox__body {
    border-color: #eb822d;
}

.accordion_ninebox .accordion_item_ninebox_red .accordion_button {
    background-color: #ab111e;
    color: #ffffff
}

.accordion_ninebox .accordion_item_ninebox_red .ninebox__number {
    color: #ab111e;
}

.accordion_ninebox .accordion_item_ninebox_red .ninebox__body {
    border-color: #ab111e;
}