.help-center-toast-container .MuiAlert-root {
  color: var(--dark-gray);
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  min-width: 411px;
  max-width: 100%;
}
.help-center-toast-container .MuiAlert-root div {
  padding: 0px;
  overflow: hidden;
  display: flex;
}

.help-center-toast-container .MuiAlert-root div span {
  margin-right: 5px;
}

.help-center-toast-container .MuiAlert-root div p.toast-action {
  color: var(--main-blue);
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  text-transform: none;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
}

.help-center-toast-container .MuiAlert-root button svg.MuiSvgIcon-root {
  height: 25px;
  width: 25px;
  opacity: 0.5;
}

.help-center-toast-container .MuiAlert-icon svg.MuiSvgIcon-root {
  height: 32px;
  width: 32px;
}

.help-center-toast-container .MuiAlert-root.MuiAlert-standardSuccess {
  background-color: var(--notification-light-green);
  border: 1px solid var(--notification-main-green);
}

.help-center-toast-container .MuiAlert-root.MuiAlert-standardSuccess svg {
  color: var(--notification-dark-green);
}

.help-center-toast-container .MuiAlert-root.MuiAlert-standardInfo {
  background: var(--notification-light-teal);
border: 1px solid var(--notification-main-teal);
}

.help-center-toast-container .MuiAlert-root.MuiAlert-standardInfo svg {
  color: var(--notification-dark-teal);
}

.help-center-toast-container .MuiAlert-root.MuiAlert-standardWarning {
  background: rgba(243, 146, 0, 0.15);
  border: 1px solid var(--notification-light-yellow);
}

.help-center-toast-container .MuiAlert-root.MuiAlert-standardWarning svg {
  color: var(--notification-dark-yellow);
}

.help-center-toast-container .MuiAlert-root.MuiAlert-standardError {
  background-color: var(--notification-light-red);
  border: 1px solid var(--notification-main-red);
}
.help-center-toast-container .MuiAlert-root.MuiAlert-standardError svg {
  color: var(--notification-dark-red);
}

.feedback_container .MuiSnackbar-root {
  position: absolute;
  width: 70%;
}
.feedback_container .end_feedback .MuiSnackbar-root {
  top: 50px;
}

.feedback_container .comment_box_wrapper .MuiSnackbar-root {
  top: 380px;
}
.feedback_container .comment_box_wrapper.negative .MuiSnackbar-root {
  top: 340px;
}

@media screen and (min-width: 320px) and (max-width: 720px) {
  .help-center-toast-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .help-center-toast-container .MuiAlert-root {
    min-width: 320px;
    max-height: 95px;
    margin-bottom: 14px;
    align-items: flex-start;
  }

  .help-center-toast-container .MuiAlert-root.MuiAlert-standardSuccess {
    align-items: center;
  }

  .feedback_container .comment_box_wrapper .MuiSnackbar-root {
    position: unset;
  }
  .feedback_container .MuiSnackbar-root {
    width: 320px;
  }

  .feedback_container .comment_box_wrapper .MuiAlert-root div {
    max-width: 220px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .feedback_container .comment_box_wrapper div span {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .feedback_container .comment_box_wrapper div button[title='Close'] {
    align-self: flex-end;
    padding: 0px;
    width: 28px;
    position: relative;
    top: -12px;
  }
}
