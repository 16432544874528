li.MuiBreadcrumbs-separator {
  line-height: 12px;
  font-size: 16px;
  font-family: var(--font-family);
  color: var(--soft-gray);
  font-weight: 500;
  margin: 0px;
}

div.popper_menu .MuiPopover-paper {
  display: flex;
  flex-direction: column;
  padding: 0px;
  border-radius: 4px;
  box-shadow: 0px 2px 9px rgba(226, 228, 233, 0.95);
  border: transparent;
}

div.popper_menu .MuiPopover-paper a {
  padding: 8px;
  border-bottom: 1px solid var(--soft-gray);
}
div.popper_menu .MuiPopover-paper a:last-of-type {
  border-bottom: none;
}

.lg-icon.lg-zoom-out {
  opacity: 1 !important;
  pointer-events: visible !important;
}

.lg-image {
  transition: transform .2s; /* Animation */
}

.lg-image.zoom-in{
  transform: scale(1.5) !important;
}

@media screen and (min-width: 320px) and (max-width: 720px) {

  #content.active {
    padding: 34px 24px 34px 18px;
  }

  div.popper_menu .MuiPopover-paper {
    width: 192px;
  }

  div.popper_menu .MuiPopover-paper a {
    padding: 16px 8px;
  }
}