.breadcrumb_item+.breadcrumb_item {
    padding-left: 0.5rem;
}

.breadcrumb_item+.breadcrumb_item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/")
        /* rtl: var(--bs-breadcrumb-divider, "/") */
    ;
}

.breadcrumb_item.active {
    color: #045793;
}

.breadcrumbs {
    font-size: 13px;
    color: #0169B5;
}

.breadcrumbs .breadcrumb_item.active {
    color: #0169B5;
}

.breadcrumbs .breadcrumb_item+.breadcrumb_item::before {
    color: #0169B5;
}

.breadcrumbs a {
    text-decoration: none;
    color: #0169B5;
}

/* a.breadcrumb_item {
    line-height: 12px;
    font-size: 10px;
    text-align: left;
    font-family: var(--font-family);
    color: #0169B5;
    font-weight: 500;
    text-decoration: none;
    margin-right: 5px;
    margin-left: 5px;
} */

/* li.breadcrumb_item {
    color: #0169B5;
}

a.breadcrumb_item:hover {
    text-decoration: underline;
}

.breadcrumb_item.active {
    color: var(--soft-blue);
}

.breadcrumb_item.active:hover {
    text-decoration: none;
}

a.breadcrumb_item i {
    margin-right: 2px;
    color: var(--ligth-blue);
} */

button.breadcrumb_collapse {
    color: var(--ligth-blue);
    padding-top: 0px;
    padding-right: 2px;
    padding-left: 2px;
    padding-bottom: 0px;
}

button.breadcrumb_collapse svg {
    color: var(--main-blue);
    height: 20px;
}

div.popper_menu_arrow {
    position: fixed;
    background-color: transparent;
    z-index: -1;
}

div.popper_menu_arrow2 {
    position: fixed;
    z-index: 3;
}

div.popper_menu_arrow::before {
    background-color: white;
    content: '';
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: -8px;
    left: 12px;
    z-index: -1;
    transform: rotate(45deg);
    box-shadow: 0px 2px 9px rgba(226, 228, 233, 0.95);
}

div.popper_menu_arrow2::before {
    background-color: white;
    content: '';
    display: block;
    position: absolute;
    width: 17px;
    height: 13px;
    top: -6px;
    left: 14px;
    transform: rotate(45deg);
}