  /* SOLICITUD VACACIONES */
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3;
  }

  .color-primary {
    color: #0169b5 !important;
  }

  .text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }

  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
  }

  .form-control-plaintext.form-control-sm,
  .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }

  /* Box */
  section>.card-box,
  form>.card-box {
    padding: 15px;
  }

  /* Box Border*/
  .card-box {
    border: 1px solid rgb(0 0 0 / 10%) !important;
    padding: 10px !important;
    border-radius: 10px;
  }

  .need-validation {
    padding: 0 5px;
    width: inherit;
  }

  .fields-dates {
    padding-right: 10px;
    margin-bottom: 3px;
  }

  .fields .label,
  .fields-dates .label {
    display: flex;
    color: var(--gray-intermediate);
    font-size: 13px;
    font-weight: 400;
    width: 100%;
  }

  .fields input,
  .fields-dates input,
  .fields-dates textarea,
  .fields textarea .fields-dates select,
  .fields select {
    padding: 10px;
    width: 100%;
    font-size: var(--font-size-sm);
    line-height: 1;
  }

  .error-colour {
    color: var(--bs-red) !important;
  }

  .error-border {
    color: var(--gray) !important;
    border: 1px solid var(--bs-red) !important;
  }

  .error-label {
    color: var(--bs-red) !important;
    font-size: 11px;
  }

  .terms-legals {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
  }

  .terms-legals-title {
    font-size: 13px;
    font-weight: 700;
    display: flex;
    align-items: center !important;
    flex-direction: row;
  }

  .red-asterisk {
    font-size: 13px;
    color: red;
  }

  .terms-legals-text {
    text-align: justify;
    font-size: 12px;
    font-weight: 300;
  }

  .terms-legals-accept {
    display: flex;
    align-items: center;
    font-size: 13px;
    padding-top: 10px;
  }

  .check-input {
    width: 14px !important;
    height: 14px !important;
    margin: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: 2px solid rgb(0 0 0 / 10%);
    box-shadow: none;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    cursor: pointer;
  }

  .check-input:checked::after {
    height: inherit;
    width: 100%;
    content: '✔';
    font-size: 12px !important;
    color: white;
    background: #f39200 !important;
    outline: 1px solid #f39200;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    border-radius: inherit;
  }

  .anchor-margin {
    margin-left: 5px;
  }

  .terms-anchor {
    cursor: pointer;
  }

  .sections {
    max-height: 100% !important;
    margin-bottom: 20px;
  }

  .space-around {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .space-between {
    display: flex;
    justify-content: space-between !important;
    flex-wrap: wrap;
  }


  /* Define default styles */
  .summary-card {
    margin-bottom: 15px;
    padding: 8px !important;
    height: auto;
    min-height: 482px;
    font-size: 13px;
  }

  .holiday-card {
    height: auto;
    min-height: 482px;
    margin-left: 10px;
    overflow-y: hidden;
  }


  @media screen and (min-width: 1200px) {

    .summary-card,
    .holiday-card {
      min-height: 80vh;
    }
  }

  .replacements {
    display: none;
    animation: slideUp 0.5s forwards;
  }

  .replacements-active {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    animation: slideDown 0.5s forwards;
  }

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideUp {
    from {
      opacity: 1;
      transform: translateY(0);
    }

    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }


  .replacements-arrow {
    color: #f59300;
    font-size: 15px;
    cursor: pointer;
    margin-left: 5px;
    transition: transform 0.3s ease;
  }

  .replacements-arrow.arrow-active {
    transform: rotate(90deg);
  }

  .overflow-y-auto {
    overflow-y: auto !important;
  }

  .holiday-card-content {
    height: 100% !important;
    width: 100% !important;
  }

  .holiday-card-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 !important;
    margin-bottom: 2px;
  }

  .padding-custom {
    padding-left: 20px !important;
    padding-right: 10px;
  }

  .error-tags {
    margin-bottom: 5px;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width:1200px) {
    .holiday-card {
      height: auto !important;
    }
  }

  @media (max-width:768px) {
    .holiday-card {

      margin-left: 0px !important;
      height: auto !important;
    }

    .holiday-card-section {
      display: block;
    }
  }

  .btn-container {
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100%;
  }


  @media (max-width:748px) {
    .data-container {
      margin-left: 12px !important;
    }

    .inner {
      padding-bottom: 16px;
    }

    .table-aprobacion-vacaciones {
      display: none;
    }
  }

  @media (max-width:545px) {
    .holiday-card .btn-container {
      margin-bottom: 11px;
      display: block !important;
      text-align: center !important;
      width: 100% !important;
      box-sizing: border-box;
      padding-right: 8px;
    }

    .btn {
      width: 100% !important;
      margin-bottom: 5px;
      line-height: 1.5;
    }

    .holiday-container .summary-card {
      max-height: 455px !important;
      min-height: 455px;
    }

    .holiday-btn {
      display: flex;
      flex-direction: column-reverse !important;
    }

    .data-summary {
      min-height: 450px !important;
      max-height: 450px;
    }

    .header-page-container {
      display: block !important;
    }

    .spacing-responsive {
      margin: 5px;
    }
  }

  .summary-card .subtitle:last-child {
    margin-bottom: 5px;
  }

  .data-summary {
    margin-bottom: 15px;
    padding: 10px !important;
    height: 100vh;
    min-height: 100vh;
    font-size: 13px;
    overflow: auto;
    width: 100%;

  }

  .main-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    min-width: 30px;
  }

  .main-image img {
    width: 120px !important;
    height: 120px !important;
    margin-top: 15px;
    border-radius: 50%;
  }


  .greet-msg {
    margin-left: 20px !important;
  }

  .detail-user {
    text-align: center;
  }

  .detail-user .title {
    font-size: var(--font-size-md-x1);
    font-weight: 800;
    text-align: center;
    padding-bottom: 5px;
    color: #11427c;
  }

  .detail-user .subtitle {
    color: slategray;
    font-size: var(--font-size-sm);
    font-weight: 400;
    text-align: center;
    padding-bottom: 2px;
  }


  .summary-card .datas-group {
    margin-top: 15px !important;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    line-height: 2;
  }

  .desktop {
    display: block;
  }

  .summary-card .datas-group .data-group {
    display: flex;
    margin: 5px 5px;
    align-items: center;
    border-bottom: 1px solid rgb(218, 217, 216);
    width: 100%;
    padding: 5px;
  }

  .summary-card .datas-group .data-group .days {
    font-size: 16px;
    color: #fff;

  }

  .info-layout {
    color: #11427c;
    font-size: 14px;
    font-weight: 500;
  }

  /*BUTTONS*/
  .holiday-btn {
    display: flex;
    flex-direction: row;
  }

  .btn {
    width: 120px;
    border-radius: 5px;
    border: 1px transparent solid;
    margin: 0 5px;
    margin-bottom: 5px;
    padding: 5px;
    text-align: center;
    line-height: 1.5;
    opacity: 0.85;
  }

  .save {
    color: #ffffff;
    border-color: #f39200;
    background: #f59300;
    background: linear-gradient(90deg, #f59300 0%, #dd494a 100%);
    transition: opacity 0.3s;
  }

  .cancel {
    color: black;
    background-color: #D7DBDD;
    transition: opacity 0.3s;
  }

  .history {
    color: #ffffff;
    background-color: #207aba;
    transition: opacity 0.3s;
  }

  .btn:hover {
    opacity: 1;
  }

  .history a {
    text-decoration: none;
    color: #fff !important;
  }

  .color-white {
    color: #fff !important;
  }

  .color-black {
    color: black !important;
  }

  /*BUTTONS*/

  .approver {
    font-size: var(--font-size-md-x1);
    font-weight: 600;
    text-align: center;
    padding-bottom: 5px;
  }

  .approver-title {
    text-transform: uppercase;
  }

  .approver-subtitle {
    font-size: 13px;
    text-align: center;
    margin-bottom: 10px;
  }

  .approver-title span {
    color: #11427c;
  }

  /* APROBACION VACACIONES */

  .form-select {
    display: block;
    margin: 0;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }

  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none;
    }
  }

  .form-select:focus {
    border-color: #80b4da;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(1, 105, 181, 0.25);
  }

  .form-select[multiple],
  .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none;
  }

  .form-select:disabled {
    background-color: #e9ecef;
  }

  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529;
  }

  .form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
  }

  .form-select-lg {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
  }

  .button:disabled {
    pointer-events: none;
    opacity: .65;
  }

  .error-icon {
    margin-right: 5px;
  }

  .error-container-lb {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }

  .full-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
      /* rtl:ignore */
    }
  }

  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
  }

  .spinner-border-sm {
    width: 13px;
    height: 13px;
    border-width: 0.2em;
    animation: spinner-border 0.75s linear infinite;
  }

  @keyframes spinner-grow {
    0% {
      transform: scale(0);
    }

    50% {
      opacity: 1;
      transform: none;
    }
  }

  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: 0.75s linear infinite spinner-grow;
  }

  .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
  }

  @keyframes spinner-border {
    to {
      transform: rotate(360deg)
        /* rtl:ignore */
      ;
    }
  }

  @media (prefers-reduced-motion: reduce) {

    .spinner-border,
    .spinner-grow {
      animation-duration: 1.5s;
    }
  }

  /* FIN SOLICITUD VACACIONES */

  .table_columna{ 
    background-color: white !important;
  }
 
  .pagination_container{
    padding-top: 50px;
  }
