.search_icon {
  position: relative;
  top: 13px;
  left: 18px;
  font-size: 28px;
  color: var(--dark-gray);
}

.search_icon.label_icon {
  top: 0px;
  left: -5px;
  font-size: 24px;
  color: var(--medium-gray)
}

.searcher_fail_text_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-bottom: 0.8px solid var(--ligth-gray);
}

.searcher_fail_text_wrapper .search_icon_main {
  width: 60px;
  height: 46px;
  margin-right: 10px;
}

.searcher_fail_text_wrapper .search_text_column {
  display: flex;
  flex-direction: column;
}

.searcher_fail_text_wrapper .search_text_column p.main_text {
  color: var(--dark-gray);
  line-height: 16.94px;
  margin-bottom: 8px;
  margin-top: 24px;
}

.searcher_fail_text_wrapper .search_text_column p.suggestions {
  color: var(--ligth-gray);
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 24px;
}

.searcher_fail_options_wrapper {
  padding: 12px 24px 0px 24px;
}

.searcher_fail_options_wrapper p.recomendations {
  color: var(--ligth-gray);
  margin-bottom: 28px;
}

.searcher_fail_options {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.searcher_fail_options div {
  display: flex;
  align-items: center;
  margin-right: 19px;
  margin-bottom: 17px;
  width: 30%;
  cursor: pointer;
}

.searcher_fail_options span.option_text {
  color: var(--ligth-gray);
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

/* mobile */
@media screen and (min-width: 320px) and (max-width: 720px) {
  .searcher_fail_options_wrapper p.recomendations {
      margin-bottom: 14px;
  }

  .searcher_fail_options {
      align-content: flex-start;
      max-height: 140px;
  }

  .searcher_fail_options div {
      margin-right: 15px;
      margin-bottom: 10px;
      width: 45%;
      flex-wrap: nowrap;
      align-items: flex-start;
  }
}

@media screen and (min-width: 320px) and (max-width: 360px) {
  .searcher_fail_options_wrapper {
      padding: 12px 7px 0px 7px;
  }
}

@media screen and (min-width: 361px) and (max-width: 375px) {
  .searcher_fail_options_wrapper {
      padding: 12px 10px 0px 10px;
  }
}

@media screen and (min-width: 376px) and (max-width: 720px) {
  .searcher_fail_options_wrapper {
      padding: 12px 20px 0px 20px;
  }
}