.box-container {
    border: 1px solid rgba(0, 0, 0, 10%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    padding: 15px;
}

.replacement-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.date-input {
    position: relative;
}

.date-input::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f59300"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 2c.55 0 1 .45 1 1v2h10V3c0-.55.45-1 1-1s1 .45 1 1v2c1.11 0 2 .89 2 2v14c0 1.11-.89 2-2 2H5c-1.11 0-2-.89-2-2V7c0-1.11.89-2 2-2zM5 21h14V10H5v11zm2-9h10v2H7v-2z"/></svg>');
    background-size: cover;
    z-index: 0;
}

.date-input::-webkit-calendar-picker-indicator {
    filter: opacity(0);
    cursor: pointer;
    z-index: 1;
}

.box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-radius: 5px;
    padding: 10px;
}

.center-align {
    display: flex;
    justify-content: center !important;
}

.block {
    display: block;
}

.pr-0 {
    padding-right: 0 !important;
}

.dates-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.label {
    color: #5d5d5d;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
}

.button-container {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    justify-content: end;
    width: inherit;
}

.save-button {
    color: #fff;
    border-color: #f39200;
    background: #f59300;
    background: linear-gradient(90deg, #f59300, #dd494a);
    width: 7.6rem;
    line-height: 1.5;
    border-radius: 5px;
    padding: 6px;
    border: 1px solid transparent;
    -webkit-appearance: button;
    appearance: button;
    opacity: 1;
}

.save-button-load {
    opacity: 0.8;
}

.table-box-container {
    border: 1px solid rgba(0, 0, 0, 10%);
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border-radius: 10px;
    padding: 15px;
    width: 100%;
}

.table-head {
    font-weight: 700 !important;
    background: transparent !important;
    color: #777777;
    border-bottom: 1px solid #f5f5f5;
    font-size: 14px;
    text-transform: capitalize !important;
}

.table-content {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.body-replacement-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
}

.edit-btn {
    display: flex;
    font-size: 17px;
    color: #f39200;
    cursor: pointer;
    margin-left: 20px;
    align-items: center;
}

.status {
    text-transform: uppercase;
    font-weight: 700;
}

.valid {
    color: #0169b5;
}

.invalid {
    color: #dd494a;
}

.invalidation-disabled {
    color: #777777;
    cursor: not-allowed;
}

.table-loading-spinner {
    margin-left: 10px;
}