.helpCenter-searcher .MuiAutocomplete-root {
  display: flex;
  margin-bottom: 24px;
}

.helpCenter-searcher .MuiOutlinedInput-notchedOutline {
  border: none;
}

.helpCenter-searcher .MuiFormControl-root.MuiTextField-root label {
  padding-left: 16px;
  color: var(--dark-gray);
  font-weight: 400;
  font-size: 14px;
}

.helpCenter-searcher label.MuiFormLabel-root.MuiInputLabel-root[data-shrink="true"] {
  transform: translate(14px, 16px) scale(1);
  opacity: 0.5;
}

.helpCenter-searcher .MuiAutocomplete-root input.MuiAutocomplete-input {
  padding-left: 40px;
  caret-color: var(--background-blue);
}

.helpCenter-searcher .MuiOutlinedInput-root.MuiOutlinedInput-root {
  width: 103%;
  position: relative;
  left: -26px;
  border-radius: 8px;
  border: 0.8px solid var(--soft-gray);
  box-shadow: 0px 4px 10px rgba(213, 213, 214, 0.5);
}


.helpCenter-searcher .MuiOutlinedInput-root.MuiOutlinedInput-root svg {
  position: relative;
  left: 20px;
  font-size: 20px;
  color: var(--dark-gray);
  cursor: pointer;
}

.helpCenter-searcher .MuiOutlinedInput-root.Mui-focused {
  border: 0.8px solid var(--background-blue) !important;
}

div.MuiAutocomplete-popper[origin='helpCenter-searcher'] div.MuiAutocomplete-paper {
  border-radius: 8px;
  height: max-content;
}

div.MuiAutocomplete-popper[origin='helpCenter-searcher'] ul {
  padding-top: 0px;
  padding-bottom: 0px;
  max-height: fit-content;
}

div.MuiAutocomplete-popper[origin='helpCenter-searcher'] li.MuiAutocomplete-option {
  font-weight: 400;
  font-size: 14px;
  color: var(--dark-gray);
  padding: 16px 24px;
  border-bottom: 1px solid var(--soft-gray);
}

div.MuiAutocomplete-popper[origin='helpCenter-searcher'] li.MuiAutocomplete-option:hover {
  background-color: var(--hover-gray);
}

.helpCenter-searcher .MuiAutocomplete-endAdornment {
  display: none !important;
}

.helpCenter-searcher .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-endAdornment {
  display: none !important;
}

.helpCenter-searcher .MuiCircularProgress-root {
  transform: none;
  animation: none;
}

/* mobile */
@media screen and (min-width: 320px) and (max-width: 720px) {
  .MuiAutocomplete-noOptions {
    padding: 14px 7px !important;
    overflow: hidden;
  }
}

@media screen and (min-width: 320px) and (max-width: 360px) {
  .MuiAutocomplete-noOptions {
    min-height: 420px;
  }

}

@media screen and (min-width: 361px) and (max-width: 375px) {
  .MuiAutocomplete-noOptions {
    min-height: 380px;
  }

}

@media screen and (min-width: 376px) and (max-width: 720px) {
  .MuiAutocomplete-noOptions {
    min-height: 380px;
  }
}