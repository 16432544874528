.category_container {
  display: block;
}
.page_title_subcategory {
  margin-bottom: 4px;
}

.category_container h2.subcategory_title {
  margin-top: 48px;
}

div.back_button_wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 32px;
}
