:root {
  --font-family: "Inter", sans-serif;
  --main-blue: #016AB5;
  --secondary-blue:  #217DBE;
  --background-blue: #217DBE;
  --ligth-blue: #3196D2;
  --dark-blue: #00316D;
  --button-blue: #0069B4;
  --darkest-blue: #00316D;
  --list-item-blue: #3196D2;
  --dark-gray: #65636E;
  --ligth-gray: #9D9D9C;
  --hover-gray: #F4F4F4;
  --soft-gray: #E2E4E9;
  --medium-gray: #C4C4C4;
  --main-orange: #F39200;
  --notification-main-green: #BCE7A9;
  --notification-secondary-green: #ABB7A5;
  --notification-light-green: #DCF3D7;
  --notification-dark-green: #566E4B;
  --notification-main-yellow: #F4E6D0;
  --notification-secondary-yellow: #DED18F;
  --notification-light-yellow: #DAC7AA;
  --notification-dark-yellow: #BDA41E;
  --notification-main-teal: #A3D5F2;
  --notification-secondary-teal: #90BEDF;
  --notification-light-teal: #CDE9F6;
  --notification-dark-teal: #217DBE;
  --notification-main-red: #D9ADAE;
  --notification-secondary-red: #D3999B;
  --notification-light-red: #F8E9E9;
  --notification-dark-red: #BD271E;
}

.helpCenter_page_container{
  width: 100%;
  max-width: 1145px;
  min-width: 326px;
}

.helpCenter_page_container .main_button {
  color: white;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: none;
  height: 48px;
  width: auto;
  border-radius: 8px;
  padding: 16px 20px 16px 20px;
  background: linear-gradient(90deg, #F59300 0%, #F38E05 6%, #DD494A 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.helpCenter_page_container .main_button.disabled {
  background: var(--medium-gray);
}

.helpCenter_page_container .terciary_button {
  color: var(--button-blue);
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  text-transform: none;
  letter-spacing: -0.1px;
  cursor: pointer;
}

.categories_btn_grid {
  display: flex;
  justify-content: flex-end;
}

.results_back_btn_grid {
  display: flex;
  justify-content: center;
}

.categories_btn_grid button {
  padding-right: 0px;
}

/* mobile */
@media screen and (min-width: 320px) and (max-width: 320px) {
  .helpCenter_page_container{
    min-width: 285px;
  }

}

@media screen and (min-width: 320px) and (max-width: 720px) {

  #content.active {
    padding: 34px 24px 34px 18px;
  }

  .categories_btn_grid {
    justify-content: center;
  }

  .categories_btn_grid button {
    padding: 6px 8px;
  }

}

/* desktop XL*/
@media screen and (min-width: 1300px) and (max-width: 1640px) {
  .helpCenter_page_container{
    width: 90%;
  }
}

@media screen and (min-width: 1641px) {
  .helpCenter_page_container{
    width: 85%;
  }
}

@media screen and (min-width: 2000px) {
  .helpCenter_page_container{
    width: 78%;
  }


}

@media screen and (min-width: 2300px) {
  .helpCenter_page_container{
    width: 75%;
  }
}

@media screen and (min-width: 2500px) {
  .helpCenter_page_container{
    width: 72%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}