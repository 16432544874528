.table-container{
    padding: 40px 40px;
    min-height: auto;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    overflow-x: auto;
    overflow-y: auto;
    border: 3px solid rgb(207, 207, 207);
}
table{
    text-align: left;
    font-size: 16px;
}
table thead{
font-weight: 600 !important;
text-shadow: 1px black !important;
 color: #8D8D90;
 font-size: 17px;
}
.sort-icon{
    cursor: pointer;
    margin-left: 5px;
}
.sort-icon:hover{
    color: chocolate;
}
.table-icon{
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
}
.table-icon:hover{
    color: chocolate;
    transform: scale(1.5);
    transition: all .3s ease-in-out
}
.layer-estado{
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    text-transform:uppercase ;
}
.layer-estado.pendiente{
    color: orange;
}
.layer-estado.firmado{
    color: #207aba;
}
.layer-estado.no-conform{
    color: #dc143c;
}
.layer-estado.no-aplica{
    color:#207aba; 
}

.table-icon-eye{
    color:#17C6A3;
}
.layer-estado.inprocess{
    color:#11427c ;
    border-color: #11427c;
    border: 2px solid;
}

.page-container{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 30px;
}
.page-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ;
    color: #8D8D90;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #207aba;
    font-size: 25px;
    margin-right: 5px;
    margin-bottom: 2px;
    height: 32px;
    width: 35px;
}
button{
    font-size: 13px;
}
.page-btn:hover{
    background-color:#207aba;
    color: #fff;
}
.page-btn:hover:disabled{
    cursor: not-allowed;
    color:#8D8D90;
    background-color: #fff;
}
.pag-label{
    margin-right: 5px;
}
.page-input:disabled{
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #207aba;
    color: #fff;
    width: 35px !important;
    font-size: 13px;
    line-height: 1;
    height: 32px;
    margin-right: 5px;
    margin-bottom: 2px;
}
.page-total{
    margin-right: 5px;
    margin-bottom: 2px;
}
.mostrar{
    margin-right: 5px;
}
.pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.doc-showing{
    font-size: 13px !important;
    line-height: 1;
    margin-right: 5px;
    color: #207aba;
    border: 1px solid #207aba !important;
}
.docs-label{
    margin-left: 5px;
}
.icon-container{
    display: flex;
    align-items: center;
   
}
.file-container-if{
   padding: 10px;
}
.sign-modal-container{
    margin: 10px;
    padding: 10px;
   
   
}
.items-wrap{
   text-align: center;

}
.item-container{
    width: 100% !important;
}

.conform-btn{
    width: 40px !important;
    font-size: 15px;
    background-color:#59C151;
    color: white;
    border-radius: 5px;
    padding: 5px 5px;
    margin: 5px;
    border: none;
}

.sign-btn{
    width: 100px;
    font-size: 15px;
    background-color:#59C151;
    color: white;
    border-radius: 5px;
    padding: 5px 5px;
    margin: 5px;
    border: none;
}
.sign-btn:disabled{
    opacity:0.5;
}
.sign-btn-container{
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 5px;
   
}
button:hover:disabled{
    cursor: not-allowed;
}

.disconform-btn{
    width: 40px !important;
    font-size: 15px;
    background-color:#D88133;
    color: white;
    border-radius: 5px;
    padding: 5px 5px;
    border: none;
}
.disconform-btn:disabled{
   opacity: 0.5;
}
.sign-label{
    font-size: 13px;  
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
}
.bold-parag{
    color: black;
    font-weight: 600;
    margin-left: 3px;
}
.pin-input{
    width: 70%;
    border: 1px solid rgb(180, 180 , 180);
    border-radius: 5px;
    line-height: 1.5;
}
.pin-input:focus{
    border-color: rgba(77, 104, 251, 0.8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6);
  outline: 0 none;
    transition: 0.4s ease-in-out;
}
.no-conform-btn{
    background-color: #207aba;
    color:#fff;
}
.comments-area{
    margin-bottom: 15px;
    width: 100%;
    border: 1px solid rgb(180, 180 , 180);
    border-radius: 5px;
    line-height: 1.5;
}

.comments-area:focus{
    border-color: rgba(77, 104, 251, 0.8);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6);
    outline: 0 none;
      transition: 0.4s ease-in-out;
}
.comments-label{
    text-align: left !important;
    font-size: 13px;
    margin-bottom: 5px;
}
.pass-icon{
    cursor:pointer;
    margin-left: 2px;
    font-size: 20px;
}
.pass-icon-disconform{
    margin-left: 3px;
    font-size: 18px;
    cursor: pointer;
}
.password-on{
    color: green;
}

.pin-recovery {
    text-align: justify;
}

.pin-recovery-link {
    margin-left: 0;
    text-decoration: none;
    margin-bottom: 1px dashed;
}
.chevron{
    color: orange;
    font-size: 25px !important;
}
.tool-tip{
    color:#fff;
    background-color: #207aba;
}
.check-sign-icon{
    font-size: 40px;
    margin-bottom: 20px;
}
.modal-title-sign{
    color:#207aba;
}
.meta-container{
    padding-top: 10px;
}
.spacing{
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgb(230, 230, 230);
}
.sign-fields{
    display: flex;
    flex-direction: column;
}
.modal-btn{
    margin-right: 10px;
    height: 35px !important;
    padding: 0;
    width: 99px !important;
    border-radius: 5px;
}
.modal-btn:disabled{
    opacity: 0.5;
}
.title-underlined{
    content: "";
    width: 60px;
    height: 5px;
    display: block;
    border-radius: 5px;
    background: #f59300;
    background: linear-gradient(90deg, #f59300 0%, #dd494a 100%) !important;
  }
@media (min-width:768px){
    .mobile-table-recibos{
      display:none;
    }
  }
  @media (max-width:768px){
    .desktop-table-recibos{
        display:none;
    }
  }
