.article_wrapper {
  max-width: 722px;
  margin-top: 40px;
}
.related_articles_wrapper {
  max-width: 722px;
}
.article_title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: var(--main-blue);
  margin-top: 34px;
}
.article_p {
  color: var(--dark-gray);
  line-height: 22px;
}
.article_p span button{
  margin-bottom: 48px;
}

.article_title:last-of-type {
  margin-top: 0px;
}

.article_wrapper .page_subtitle {
  margin-bottom: 8px;
}

.article_wrapper .section_title {
  margin-top: 48px;
  margin-bottom: 32px;
}

.article_back_button_wrapper {
  max-width: 722px;
  margin-top: 48px !important;
  justify-content: center;
  display: flex;
}

.loading_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px !important;
}

.article_subtitles {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--secondary-blue);
  margin-bottom: 8px;
  margin-top: 32px;
}