.article_img {
  margin-top: 28px;
  margin-bottom: 32px;
}
.article_img img{
  max-width: 100%;
}

.article_img_mobile {
  display: none;
}

.article_img_desktop {
  display: block;
}

.article_video {
  width: 675px;
  height: 420px;
  border-radius: 8px;
}

.subcategory h2 {
  margin-top: 48px;
}

.close_button_row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}

.modal_video_article .MuiPaper-root{
  width: 1000px;
  height: 435px;
}

.video_modal {
  width: 100%;
  height: 100%;
}

/* mobile */
@media screen and (min-width: 320px) and (max-width: 720px) {

  .article_img {
    max-width: 100%;
  }

  .article_video {
    width: 100%;
    max-height: 250px;
  }

  .article_img_mobile {
    display: block;
  }

  .article_img_desktop {
    display: none;
  }
}

/* tablet */
@media screen and (min-width: 500px) and (max-width: 990px) {

  .article_video {
    max-height: 350px;
  }

}