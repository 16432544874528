@charset "UTF-8";

/* Placeholders */
.btn.btn-degradado,
.box-objetivos::before,
.tit-section::after {
  background: #f59300;
  background: linear-gradient(90deg, #f59300 0%, #dd494a 100%);
}

.btn.btn-degradado:hover,
.box-objetivos:hover::before,
.tit-section:hover::after {
  filter: brightness(0.95);
}

.btn.btn-border-box[aria-expanded=true],
.btn.btn-border-box.active,
.btn.btn-xl.btn-degradado-2,
.btn.btn-lg-2.btn-degradado-2,
.btn.btn-lg-2:hover {
  background: #0067b7;
  background: linear-gradient(90deg, #0067b7 20%, #56b8e9 85%, #66e09c 100%);
}

.btn.btn-border-box[aria-expanded=true]:hover,
.btn.btn-border-box.active:hover,
.btn.btn-xl.btn-degradado-2:hover,
.btn.btn-lg-2:hover {
  filter: brightness(0.95);
}

.list-dscto__number,
.login--cencosud {
  background: #0069b4;
  background: linear-gradient(90deg, #0069b4 25%, #56b8e9 100%);
}

.box-welcome__icon {
  background: #95fbb1;
  background: linear-gradient(0deg, #95fbb1 0%, #56b8e9 30%, #0067b7 100%);
}

.img-article-intro__info,
.carousel .carousel-inner .carousel-item .info-carousel {
  background: black;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, black 100%);
}

h1,
h2,
h3,
.h1 {
  color: #0169B5;
  margin-bottom: 20px;
  position: relative;
}

h1 {
  font-size: 30px;
  font-weight: 700;
}

h2 {
  font-size: 30px;
  font-weight: 700;
}

h3 {
  font-weight: 600;
  font-size: 22px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.h1 {
  font-weight: 700;
  font-size: 30px;
}

.h3 {
  font-size: 22px;
  font-weight: 600;
}

.h4 {
  font-size: 18px;
}

.h5 {
  font-size: 16px;
}

.h6 {
  font-size: 16px;
}

p {
  font-size: 14px;
}

.fa-xs {
  font-size: 12px;
}

.tit-section {
  position: relative;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 22px;
}

.tit-section::after {
  content: "";
  width: 60px;
  height: 5px;
  display: block;
  border-radius: 5px;
}

.tit-section-center::after {
  position: absolute;
  left: calc(50% - 30px);
  bottom: -5px;
}

.accordion-header,
.accordion-header .accordion-button {
  font-size: 14px;
}

section>.box,
form>.box {
  padding: 20px;
}

@media (min-width: 992px) {

  section>.box,
  form>.box {
    padding: 30px;
  }
}

.box {
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}

@media (min-width: 992px) {
  .box {
    padding: 30px;
  }
}

.box-scroll {
  overflow-x: hidden;
  max-height: 300px;
}

.box-scroll-x2 {
  overflow-x: hidden;
  max-height: 400px;
}

.box-welcome {
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  margin-bottom: 60px;
}

@media (min-width: 768px) {
  .box-welcome {
    min-height: 150px;
  }
}

.box-welcome__icon {
  display: flex;
  width: 80px;
  height: 80px;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  border-radius: 70px;
  margin: -70px auto 15px auto;
}

.box-welcome__icon .fa {
  font-size: 35px;
}

.box-card {
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.box-card__img img {
  border-radius: 10px 10px 0 0 !important;
}

.box-card__body {
  padding: 30px;
  line-height: 1.5;
}

.box-card__body h3 {
  margin-bottom: 10px;
}

.box-card__body h3 a {
  text-decoration: none;
}

.box-dscto__img img {
  border-radius: 10px 10px 0 0 !important;
}

.box-dscto__body {
  padding: 5px 5px 15px 5px;
  line-height: 1.1;
  background-color: #eeeeee;
  border-radius: 0 0 10px 10px;
  text-align: center;
}

.box-dscto__body p {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 18px;
  color: #333;
}

.box-dscto__body strong {
  font-size: 40px;
  font-weight: 900;
  display: block;
}

.box-videos__img {
  position: relative;
}

.box-videos__img img {
  border-radius: 10px 10px 0 0 !important;
}

.box-videos__img .fa {
  font-size: 40px;
  position: absolute;
  z-index: 1;
  left: calc(50% - 28px);
  top: calc(50% - 28px);
  color: #ffffff;
}

.box-videos__body {
  padding: 20px;
  line-height: 1.1;
  background-color: #eeeeee;
  border-radius: 0 0 10px 10px;
}

.box-videos__body p {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 18px;
  color: #333;
}

.box-videos__body strong {
  font-size: 40px;
  font-weight: 900;
  display: block;
}

.box-videos__body a {
  text-decoration: none;
}

.box-videos__body h3 {
  font-weight: 600;
}

.box-videos__body h3 a {
  color: #333;
}

.box-videos__info {
  color: #0169B5;
}

.box-videos__info li {
  margin-bottom: 5px;
}

.box-videos__info a {
  font-weight: 600;
}

.box-objetivos {
  width: 100%;
  padding-top: 30px;
  position: relative;
}

.box-objetivos::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 15px;
  display: block;
  border-radius: 10px 10px 0 0;
}

.box-objetivos h2,
.box-objetivos .h3 {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px;
  margin-bottom: 0;
}

.welcome {
  text-align: center;
  margin: 70px auto 90px auto;
}

@media (min-width: 768px) {
  .welcome {
    width: 340px;
  }
}

/* Breadcrumbs */
.breadcrumbs {
  font-size: 12px;
  color: #9d9d9c;
}

.breadcrumbs .breadcrumb-item.active {
  color: #9d9d9c;
  font-weight: 600;
}

.breadcrumbs .breadcrumb-item+.breadcrumb-item::before {
  color: #9d9d9c;
}

.breadcrumbs a {
  text-decoration: none;
  color: #9d9d9c;
}

/* Login */
@media (min-width: 768px) {
  .login-page {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.login {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
  margin: 100px 20px 20px 20px;
}

@media (min-width: 768px) {
  .login {
    min-width: 720px;
    margin: 0 0 0 45px;
    padding: 70px 50px;
  }
}

.login--absolute {
  position: relative;
  top: -100px;
  left: -50px;
  margin-bottom: -80px;
}

@media (min-width: 768px) {
  .login--absolute {
    position: absolute;
    left: -45px;
    top: 50%;
    transform: translateY(-50%);
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 0) and (max-width: 767px) {
  .login--absolute {
    width: calc(100% + 50px);
  }
}

.login--cencosud {
  padding: 30px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0 40px 40px 0;
}

@media (min-width: 768px) {
  .login--cencosud {
    padding: 50px 30px;
  }
}

.login--margin {
  margin-right: 25px;
}

.new-login {
  background-position: center center;
}

@media (min-width: 768px) {
  .new-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh);
  }
}

.new-login .logo-login {
  position: absolute;
  left: calc(50% - 65px);
  top: -70px;
}

.new-login .box {
  background: #ffffff;
  margin: 100px 0;
  padding: 80px 50px 50px 50px;
}

@media (min-width: 768px) {
  .new-login .box {
    margin: 0;
  }
}

.new-login footer {
  width: 100vw;
  left: 0;
  bottom: 0;
}

@media (min-width: 768px) {
  .new-login footer {
    position: absolute;
  }
}

/* Buttons */
.fa-fw {
  margin: 0 auto;
}

.btn {
  border-radius: 8px;
  font-size: 14px;
}

.btn.btn-primary {
  background-color: #0169B5;
}

.btn.btn-link {
  color: #0169B5;
}

.btn.btn-degradado {
  color: #ffffff;
  border-color: #f39200;
}

.btn.btn-degradado:hover {
  color: #ffffff;
}

.btn.btn-degradado:hover .fa {
  color: #ffffff;
}

.btn.hover-white:hover {
  color: #ffffff !important;
}

.btn.hover-white:hover span {
  color: #ffffff !important;
}

.btn.hover-white:hover .fa {
  color: #ffffff !important;
}

.btn.btn-lg-2 {
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  text-align: left;
  line-height: 1;
  position: relative;
  display: flex;
  border: 1px solid #2C6AAE;
  font-weight: 600;
}

.btn.btn-lg-2:hover {
  color: #ffffff;
}

.btn.btn-lg-2 .fa {
  color: #f39200;
  font-size: 16px;
}

.btn.btn-lg-2.btn-degradado-2 {
  color: #ffffff !important;
}

.btn.btn-lg-2.btn-degradado-2 .fa,
.btn.btn-lg-2.btn-degradado-2 span {
  color: #ffffff !important;
}

.btn.btn-xl {
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  text-align: left;
  line-height: 1;
  position: relative;
  height: 130px;
  display: flex;
  border: 1px solid #2C6AAE;
  font-weight: 600;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  color: #0169B5;
}

.btn.btn-xl:hover {
  background-color: #eeeeee;
}

.btn.btn-xl .fa {
  position: absolute;
  bottom: 15px;
  right: 15px;
  font-size: 20px;
  color: #f39200;
  font-size: 26px;
}

.btn.btn-xl.btn-degradado-2 {
  color: #ffffff;
  border: 1px solid #eeeeee;
}

.btn.btn-xl.btn-degradado-2 .fa {
  color: #ffffff;
}

.btn.btn-outline-primary {
  color: #0169B5;
  border-color: #0169B5;
}

.btn.btn-outline-primary:hover {
  background-color: #0169B5;
  color: #ffffff;
}

.btn.btn-outline-primary:hover .fa {
  color: #ffffff;
}

.btn.btn-outline-primary.active {
  color: #ffffff;
  background-color: #0169B5;
}

.btn.btn-border-box {
  border-radius: 10px;
  padding: 20px 40px;
  line-height: 1;
  position: relative;
  border: 1px solid #2C6AAE;
  font-weight: 600;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  color: #0169B5;
  text-transform: uppercase;
  margin: 0 10px;
}

.btn.btn-border-box:hover {
  background-color: #eeeeee;
}

.btn.btn-border-box .fa {
  font-size: 16px;
  color: #f39200;
  margin-left: 5px;
}

.btn.btn-border-box.active {
  color: #ffffff;
  border: 1px solid #eeeeee;
}

.btn.btn-border-box.active .fa {
  color: #ffffff;
}

.btn.btn-border-box.active::before {
  content: "";
  position: absolute;
  left: calc(50% - 10px);
  width: 0;
  height: 0;
  border-top: 10px solid #278cce;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  bottom: -19px;
}

.btn.btn-border-box[aria-expanded=true] {
  color: #ffffff;
  border: 1px solid #eeeeee;
}

.btn.btn-border-box[aria-expanded=true] .fa {
  color: #ffffff;
}

.btn.btn-border-box[aria-expanded=true]::before {
  content: "";
  position: absolute;
  left: calc(50% - 10px);
  width: 0;
  height: 0;
  border-top: 10px solid #278cce;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  bottom: -19px;
}

.btn.btn-orange {
  border: 1px solid #f39200;
  color: #0169B5;
}

.btn.btn-orange .fa {
  color: #f39200;
}

.btn .badge {
  left: 35px !important;
  top: 8px !important;
}

.btn.btn-notification {
  position: relative;
}

.btn.btn-outline-primary .fa {
  color: #0169B5;
}

.btn.btn-outline-orange {
  border-color: #f39200;
}

.btn.btn-outline-orange::after {
  border-top-color: #f39200;
}

.btn-absolute {
  position: absolute;
  right: 50px;
  bottom: 30px;
}

.btn>[class*=fa-chevron-] {
  font-size: 12px;
}

.btn>[class*=fa-chevron-right] {
  margin-left: 5px;
}

.btn>[class*=fa-chevron-left] {
  margin-right: 5px;
}

.btn .fa {
  color: #ffffff;
}

.btn-link .fa {
  color: #f39200;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  filter: grayscale(100%);
}

::-webkit-calendar-picker-indicator {
  display: block;
}

.btn-group-switch .btn-default {
  background-color: #eeeeee;
}

.btn-group-switch .btn-default .fa {
  color: #0169B5;
}

.btn-group-switch .active {
  background-color: #ffffff;
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
}

.link-list {
  color: #777777;
}

.link-list.selected,
.link-list:hover {
  color: #777777;
  background: #f5f5f5;
}

.link-list.selected .data-list-box ul li,
.link-list:hover .data-list-box ul li {
  border-bottom-color: #ffffff;
}

.btn-close-white {
  opacity: 1;
}

/* Tabs */
.nav-tabs {
  border-bottom: 1px solid #0169B5;
}

.nav-tabs .nav-item .nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0;
  color: #0169B5;
}

.nav-tabs .nav-item .nav-link.active {
  background-color: #0169B5;
  color: #ffffff;
  border-color: #0169B5;
}

.nav-tabs .nav-item .nav-link:hover {
  border-color: transparent;
}

.nav-with-buttons {
  border-bottom: 0;
  justify-content: center;
}

/* Acordeón */
#accordion-ad .accordion-body {
  padding: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion .accordion-button {
  background-color: #0169B5;
  color: #ffffff;
}

.accordion .accordion-button[aria-expanded=true] {
  background-color: #0169B5;
  color: #ffffff;
}

.accordion .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-light .accordion-button {
  background-color: #ffffff;
  color: #777777;
}

.accordion-light .accordion-button[aria-expanded=true] {
  background-color: #ffffff;
  color: #777777;
}

.accordion-light .accordion-item {
  border-bottom-color: #0169B5;
}

.accordion-table .accordion-button .fa {
  color: #0169B5;
}

.accordion-table .accordion-button .form-check-input:checked {
  background-color: #0169B5;
  border-color: #0169B5;
}

.accordion-table .accordion-button .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.accordion-table .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230169B5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-table .accordion-button:not(.collapsed) {
  box-shadow: inset 0 -1px 0 #0169b5;
  background-color: #0169B5;
  color: #ffffff;
}

.accordion-table .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-table .accordion-button:not(.collapsed) .fa {
  color: #ffffff;
}

.accordion-table .accordion-button:not(.collapsed) .form-check-input:checked {
  background-color: #ffffff;
  border-color: #ffffff;
  color: red;
}

.accordion-table .accordion-button:not(.collapsed) .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%230169B5' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.accordion-table .accordion-button strong {
  margin-right: 5px;
}

.accordion-with-buttons .accordion-item {
  border: 0;
  margin-bottom: 20px;
}

.accordion-with-buttons .accordion-item .accordion-header {
  text-align: center;
}

.accordion-with-buttons .accordion-item .accordion-body {
  padding: 40px 40px 20px 40px;
}

.accordion-button {
  align-items: flex-start;
  flex-direction: column;
}

.accordion-button::after {
  position: absolute;
  right: 20px;
  top: calc(50% - 10px);
}

/* Carousel */
.carousel {
  padding-bottom: 50px;
}

.carousel .carousel-control-prev,
.carousel .carousel-control-next {
  width: 5%;
  height: calc(100% - 50px);
  display: none;
}

.carousel .carousel-control-prev-icon,
.carousel .carousel-control-next-icon {
  height: 3rem;
  border-radius: 3px;
  background-color: #000;
  box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.3);
}

@media (min-width: 992px) {

  .carousel .carousel-control-prev,
  .carousel .carousel-control-next {
    display: block;
  }
}

.carousel img {
  border-radius: 10px;
}

.carousel [class*=col] {
  position: relative;
}

.carousel.carousel-control-inner .carousel-item {
  padding: 0;
}

@media (min-width: 992px) {
  .carousel.carousel-control-inner .carousel-item {
    padding: 0 60px;
  }
}

.carousel .carousel-inner .carousel-item .info-carousel {
  position: absolute;
  right: 0;
  top: 0;
  text-align: right;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  border-radius: 0 10px 10px 0;
}

@media (min-width: 990px) {
  .carousel .carousel-inner .carousel-item .info-carousel {
    padding: 20px 70px;
  }
}

@media (min-width: 1280px) {
  .carousel .carousel-inner .carousel-item .info-carousel {
    width: 70%;
    padding: 20px 150px;
  }
}

.carousel .carousel-inner .carousel-item .info-carousel__body {
  color: #ffffff;
}

.carousel .carousel-inner .carousel-item .info-carousel__body h2 {
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 18px;
  color: #ffffff;
}

@media (min-width: 540px) {
  .carousel .carousel-inner .carousel-item .info-carousel__body h2 {
    font-size: 22px;
  }
}

@media (min-width: 1280px) {
  .carousel .carousel-inner .carousel-item .info-carousel__body h2 {
    font-size: 30px;
  }
}

.carousel .carousel-inner .carousel-item .info-carousel__body p {
  font-size: 14px;
}

@media (min-width: 540px) {
  .carousel .carousel-inner .carousel-item .info-carousel__body p {
    font-size: 16px;
  }
}

@media (min-width: 1280px) {
  .carousel .carousel-inner .carousel-item .info-carousel__body p {
    font-size: 18px;
  }
}

.carousel .carousel-inner .carousel-item .info-carousel__body .btn {
  border: 0;
}

.carousel-indicators [data-bs-target] {
  height: 6px;
  border-radius: 3px;
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #9d9d9c;
}

.carousel-dark .carousel-indicators [data-bs-target].active {
  background-color: #0169B5;
}

.img-article-intro {
  position: relative;
}

.img-article-intro img {
  border-radius: 10px;
}

.img-article-intro__info {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  border-radius: 0 10px 10px 0;
  width: 100%;
}

@media (min-width: 992px) {
  .img-article-intro__info {
    width: 70%;
  }
}

.img-article-intro__info h2 {
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 18px;
  color: #ffffff;
}

@media (min-width: 540px) {
  .img-article-intro__info h2 {
    font-size: 22px;
  }
}

@media (min-width: 1280px) {
  .img-article-intro__info h2 {
    font-size: 30px;
  }
}

/* Tablas */
.table {
  color: #777777;
}

.table thead tr th {
  font-weight: 300;
  background: #dbdbdb;
  font-size: 14px;
}

.table tfoot tr td {
  font-weight: 700;
  background: #0169B5;
  color: #ffffff;
}

.table-vertical-middle {
  vertical-align: middle;
}

.table-2 thead tr th {
  font-weight: 400;
  background: #0169B5;
  color: #ffffff;
}

.table-2 tbody tr {
  border-bottom: 1px solid #f5f5f5;
}

.table-2 tbody tr td .form-check-input[type=radio] {
  border-width: 2px;
  border-color: #0169B5;
}

.table-w-100 thead tr th {
  width: 10%;
}

.table-section thead tr th {
  vertical-align: top;
}

.table-section thead tr th {
  font-weight: 700;
  background: transparent;
  color: #777777;
  border-bottom: 1px solid #f5f5f5;
  font-size: 14px;
}

.table-even tbody tr:nth-child(2n+1) {
  background-color: #f5f5f5;
}

.table-even-acordeon tbody tr {
  border: 0;
}

.table-even-acordeon tbody tr:nth-child(1),
.table-even-acordeon tbody tr:nth-child(2),
.table-even-acordeon tbody tr:nth-child(5),
.table-even-acordeon tbody tr:nth-child(6),
.table-even-acordeon tbody tr:nth-child(9),
.table-even-acordeon tbody tr:nth-child(10),
.table-even-acordeon tbody tr:nth-child(13),
.table-even-acordeon tbody tr:nth-child(14),
.table-even-acordeon tbody tr:nth-child(17),
.table-even-acordeon tbody tr:nth-child(18),
.table-even-acordeon tbody tr:nth-child(21),
.table-even-acordeon tbody tr:nth-child(22),
.table-even-acordeon tbody tr:nth-child(25),
.table-even-acordeon tbody tr:nth-child(26),
.table-even-acordeon tbody tr:nth-child(29),
.table-even-acordeon tbody tr:nth-child(30) {
  background-color: #f5f5f5;
}

.table-even-acordeon .accordion-item {
  background-color: transparent;
}

.table-th-border thead tr th {
  border-bottom: 3px solid #0169B5;
}

.table-color-reset tbody tr {
  color: #777777;
}

.data-list-item {
  border-bottom: 1px solid #eeeeee;
  padding: 15px 0 10px 0;
}

.data-list-item:last-child {
  border-bottom: 0;
}

.data-list-item--text small {
  font-size: 12px;
  font-weight: 300;
}

.data-list-item--text p {
  margin-bottom: 0.3rem;
}

.data-list-box-align-middle ul li {
  align-items: center !important;
}

@media (min-width: 1280px) {
  .data-list-box-form ul li .data-list-box__text {
    min-width: 35% !important;
    width: 35%;
  }
}

.data-list-box-border-primary ul {
  border-top: 2px solid #0169B5;
  margin-bottom: 0;
}

.data-list-box-sa .data-list-section--item:nth-child(2n) {
  width: calc(100% + 40px);
}

@media (min-width: 992px) {
  .data-list-box-sa .data-list-section--item:nth-child(2n) {
    width: calc(100% + 60px);
  }
}

.data-list-box ul {
  width: 100%;
}

.data-list-box ul[class*=list-column-] .data-list-box__title {
  color: #777777;
}

@media (min-width: 768px) {
  .data-list-box ul[class*=list-column-] .data-list-box__title {
    min-width: 35%;
  }
}

.data-list-box ul[class*=list-column-] .data-list-box__text {
  color: #9d9d9c;
}

@media (min-width: 1280px) {
  .data-list-box ul[class*=list-column-1] .data-list-box__title {
    min-width: 28%;
  }
}

@media (min-width: 1280px) {
  .data-list-box ul[class*=list-column-1] .data-list-box__text {
    min-width: 71%;
  }
}

@media (min-width: 1280px) {
  .data-list-box ul[class*=list-column-50-50] .data-list-box__title {
    min-width: 50%;
  }
}

@media (min-width: 1280px) {
  .data-list-box ul[class*=list-column-50-50] .data-list-box__text {
    min-width: 50%;
  }
}

.data-list-box ul li {
  padding: 10px 0;
  border-bottom: 1px solid #eeeeee;
  position: relative;
  justify-content: flex-start;
}

@media (min-width: 768px) {
  .data-list-box ul li {
    flex-direction: row;
    align-items: flex-start;
    display: flex;
  }
}

.data-list-box ul li .data-list-box__title {
  font-weight: 700;
  margin-bottom: 4px;
  display: block;
  color: #777777;
}

@media (min-width: 768px) {
  .data-list-box ul li .data-list-box__title {
    min-width: 15%;
    display: inline;
    margin-bottom: 0;
  }
}

.data-list-box ul li .data-list-box__text {
  color: #9d9d9c;
}

@media (min-width: 768px) {
  .data-list-box ul li .data-list-box__photo {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
}

@media (min-width: 768px) {
  .data-list-box ul li .data-list-box__photo+.data-list-box__title {
    padding-left: 50px;
  }
}

.data-list-box-odd ul li {
  padding: 10px !important;
}

.data-list-box-odd ul li:nth-child(odd) {
  background-color: #f5f5f5;
}

.data-list-box-even ul li {
  padding: 10px !important;
}

.data-list-box-even ul li:nth-child(even) {
  background-color: transparent;
}

@media (min-width: 576px) {
  .data-list-box-sm-odd ul li {
    padding: 10px !important;
  }
}

@media (min-width: 576px) {
  .data-list-box-sm-odd ul li:nth-child(odd) {
    background-color: #f5f5f5;
  }
}

@media (min-width: 768px) {
  .data-list-box-md-odd ul li {
    padding: 10px !important;
  }
}

@media (min-width: 768px) {
  .data-list-box-md-odd ul li:nth-child(odd) {
    background-color: #f5f5f5;
  }
}

@media (min-width: 992px) {
  .data-list-box-lg-odd ul li {
    padding: 10px !important;
  }
}

@media (min-width: 992px) {
  .data-list-box-lg-odd ul li:nth-child(odd) {
    background-color: #f5f5f5;
  }
}

@media (min-width: 1200px) {
  .data-list-box-xl-odd ul li {
    padding: 10px !important;
  }
}

@media (min-width: 1200px) {
  .data-list-box-xl-odd ul li:nth-child(odd) {
    background-color: #f5f5f5;
  }
}

@media (min-width: 1400px) {
  .data-list-box-xxl-odd ul li {
    padding: 10px !important;
  }
}

@media (min-width: 1400px) {
  .data-list-box-xxl-odd ul li:nth-child(odd) {
    background-color: #f5f5f5;
  }
}

.img-profile {
  position: relative;
  display: inline-block;
}

.img-profile .btn {
  position: absolute;
  padding-top: 8px;
  padding-bottom: 8px;
  top: 125px;
  display: block;
  height: 40px;
  width: 40px;
  text-align: center;
}

.list-column-2,
.list-column-3 {
  -webkit-column-gap: 0;
  -moz-column-gap: 0;
  column-gap: 0;
  display: block;
}

@media (min-width: 1200px) {
  .list-column-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (min-width: 1200px) {
  .list-column-3 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (min-width: 1550px) {
  .list-column-3 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
}

.list-share {
  border-top: 1px solid #eeeeee;
  font-size: 12px;
  padding-top: 10px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .list-share {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .list-share {
    justify-content: flex-end;
  }
}

.list-share a {
  margin-left: 5px;
}

.data-list-section--item {
  margin-bottom: -1px;
}

.data-list-section--item li {
  padding: 10px 0;
  border-bottom: 1px solid #eeeeee;
  color: #9d9d9c;
}

.data-list-section--item li strong {
  color: #777777;
}

.data-list-section--item:nth-child(even) {
  background-color: #eeeeee;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 -20px;
}

@media (min-width: 992px) {
  .data-list-section--item:nth-child(even) {
    margin: 0 -30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.data-list-section--item:nth-child(even) li {
  border-bottom: 1px solid #ffffff;
  color: #777777;
}

.data-list-section--item:nth-child(even) li strong {
  color: #777777;
}

.nav-modal {
  font-size: 12px;
  color: #dbdbdb;
  margin-bottom: 25px;
}

.nav-modal ul {
  display: flex;
}

.nav-modal ul li {
  border-top: 5px solid #dbdbdb;
  padding-top: 5px;
  margin-right: 5px;
  flex: 1 1 auto;
}

.nav-modal ul li.active {
  color: #0169B5;
  border-top-color: #0169B5;
}

.accordion .data-list-section--item li {
  padding: 10px 20px;
}

.accordion .data-list-section--item:nth-child(even) {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

.history {
  color: #9d9d9c;
  font-size: 12px;
}

.history li {
  padding-left: 20px;
  margin-bottom: 15px;
}

.history li span {
  color: #0169B5;
  font-size: 14px;
  display: block;
  position: relative;
}

.history li span::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #0169B5;
  border-radius: 30px;
  position: absolute;
  display: block;
  top: 5px;
  left: -20px;
}

.upload-files li {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 15px;
}

.upload-files .fa-lg {
  margin-top: 2px;
}

.list-tab {
  text-align: center;
}

.list-tab li {
  display: inline-block;
  position: relative;
  margin-right: 8px;
  margin-bottom: 10px;
}

.list-tab li::before {
  content: "";
  width: 1px;
  height: 10px;
  background-color: #f39200;
  position: absolute;
  display: block;
  top: 6px;
  right: -7px;
}

.list-tab li:last-child::before {
  display: none;
}

.list-tab li a {
  text-decoration: none;
  padding: 2px 4px;
}

.list-tab li a:hover {
  color: #f39200;
}

.list-tab li a.active {
  color: #f39200;
  font-weight: 600;
  border-bottom: 3px solid #f39200;
}

.pasos-box__title {
  padding: 10px 0;
  color: #0169B5;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
  font-weight: 700;
}

.pasos-box__items {
  color: #9d9d9c;
}

.pasos-box__items li {
  padding: 10px 0 10px 30px;
  position: relative;
}

.pasos-box__items li::before {
  content: "";
  width: 16px;
  height: 16px;
  display: block;
  border: 2px solid #0169B5;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 15px;
}

.pasos-box__items .active {
  color: #0169B5;
}

.pasos-box__items .active::before {
  background-color: #0169B5;
}

.pasos-box__items .active::after {
  display: none;
}

.pasos-box__items .active-line::after {
  content: "";
  width: 1px;
  height: 30px;
  display: block;
  border-left: 2px solid #0169B5;
  position: absolute;
  left: 7px;
  top: 20px;
}

@media (min-width: 992px) {
  .pasos-box-txt__itemsx4 li {
    width: 25%;
  }
}

@media (min-width: 992px) {
  .pasos-box-txt__itemsx3 li {
    width: 33%;
  }
}

@media (min-width: 992px) {
  .pasos-box-txt__itemsx2 li {
    width: 50%;
  }
}

.pasos-box-txt__items {
  margin-left: 80px;
}

@media (min-width: 992px) {
  .pasos-box-txt__items {
    display: flex;
    margin-left: 0;
  }
}

.pasos-box-txt__items li {
  padding: 15px 0 15px 30px;
  position: relative;
  color: #9d9d9c;
}

@media (min-width: 992px) {
  .pasos-box-txt__items li {
    text-align: center;
  }
}

.pasos-box-txt__items li h3 {
  font-size: 14px;
  color: #9d9d9c;
  margin-bottom: 4px;
}

.pasos-box-txt__items li p {
  margin-bottom: 0;
}

.pasos-box-txt__items li::before {
  content: "";
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  left: 2px;
  top: 25px;
  transform: translateY(-50%);
  border-radius: 14px;
  background-color: #9d9d9c;
}

@media (min-width: 992px) {
  .pasos-box-txt__items li::before {
    top: 3px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.pasos-box-txt__items li::after {
  content: "";
  width: 1px;
  height: 100%;
  display: block;
  border-left: 2px solid #9d9d9c;
  position: absolute;
  left: 8px;
  top: -70%;
  transform: translateX(-50%);
}

@media (min-width: 992px) {
  .pasos-box-txt__items li::after {
    width: 100%;
    height: 1px;
    top: 9px;
    left: -50%;
    transform: translateY(-50%);
    border-top: 2px solid #9d9d9c;
  }
}

.pasos-box-txt__items li:first-child::after {
  display: none;
}

@media (min-width: 992px) {
  .pasos-box-txt__items li {
    padding: 30px 15px 15px 15px;
  }
}

.pasos-box-txt__items .checked h3 {
  color: #0169B5;
}

.pasos-box-txt__items .checked p {
  color: #333;
}

.pasos-box-txt__items .checked::before {
  border: 4px solid #0169B5;
  width: 18px;
  height: 18px;
  left: -1px;
  background-color: #0169B5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-check' viewBox='0 0 16 16'%3e%3cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3e%3c/svg%3e");
  z-index: 1;
  background-position: -3px -3px;
}

@media (min-width: 992px) {
  .pasos-box-txt__items .checked::before {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.pasos-box-txt__items .checked::after {
  border-left: 2px solid #0169B5;
}

@media (min-width: 992px) {
  .pasos-box-txt__items .checked::after {
    border-top: 2px solid #0169B5;
  }
}

.pasos-box-txt__items .active h3 {
  color: #0169B5;
}

.pasos-box-txt__items .active p {
  color: #333;
}

.pasos-box-txt__items .active::before {
  border: 4px solid #0169B5;
  width: 18px;
  height: 18px;
  left: -1px;
  background-color: #ffffff;
  z-index: 1;
}

@media (min-width: 992px) {
  .pasos-box-txt__items .active::before {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.pasos-box-txt__items .active::after {
  border-left: 2px solid #0169B5;
}

@media (min-width: 992px) {
  .pasos-box-txt__items .active::after {
    border-top: 2px solid #0169B5;
  }
}

.list-orange li::marker {
  color: #f39200;
}

.list-dscto__number {
  color: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  font-weight: 700;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}

.list-dscto__text {
  margin-left: 20px;
}

.list-dscto li {
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #eeeeee;
}

/* modal */
@media (min-width: 768px) {
  .modal-dialog .modal-footer .btn {
    margin-left: 15px;
  }
}

.modal-dialog .modal-footer .btn.col-12 {
  width: 98.5%;
}

@media (min-width: 768px) {
  .modal-dialog .modal-footer .btn.col-12 {
    width: auto;
  }
}

/* form */
.form-check-group .form-check {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 35px;
}

.form-check-group .form-check .form-check-input[type=radio] {
  border-width: 2px;
  border-color: #0169B5;
}

.form-check-group .form-check .invalid-feedback {
  margin: 15px 0 -10px -35px;
  background-color: #ffffff;
  padding: 10px 35px;
  width: calc(100% + 35px);
}

.form-check-group .form-check__title {
  padding: 10px 15px;
  background-color: #0169B5;
  color: #ffffff;
}

.form-check-group-even .form-check:nth-child(even) {
  background-color: #eeeeee;
}

.input-group-white .form-control {
  border-right-color: transparent;
}

.input-group-white .input-group-text {
  border-left-color: transparent;
  background-color: #ffffff;
}

.input-group-white .input-group-text .fa {
  color: #0169B5;
}

.input-group-btn .form-control {
  border-right-color: transparent;
}

.input-group-btn .btn {
  border-top: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
}

.input-group-btn .btn .fa {
  color: #f39200;
}

.input-group-text {
  background-color: white;
  position: relative;
  z-index: 1;
}

.input-group-text-left {
  border-right: 0;
  margin-right: 0px;
}

.form-select,
.form-control {
  font-size: 14px;
}

.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23f39200' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

label {
  font-weight: 700;
}

.form-check-label {
  font-weight: 400;
}

form .fa {
  color: #f39200;
}

.form-check-input {
  border-color: #f39200;
  border-width: 2px;
}

.form-check-input[role=switch] {
  border-width: 0;
}

.hidden-checkbox[type=checkbox]:not(:checked),
.hidden-checkbox[type=checkbox]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

/* Pagination */
.pagination .page-item {
  margin: 0 7px;
}

.pagination .page-item .page-link {
  border-color: #0169B5;
  border-radius: 5px;
  color: #0169B5;
}

.pagination .page-item .page-link .fa {
  font-size: 10px;
}

.pagination .page-item.active .page-link {
  background-color: #0169B5;
  border-color: #0169B5;
  color: #ffffff;
}

.pagination-short .btn .fa {
  color: #f39200;
}

/* Cards */
.card {
  padding: 20px;
  border-color: #0169B5;
  border-radius: 10px;
}

.card .data-list-box ul li:last-child {
  border-bottom: 0;
}

.border-orange {
  border-color: #f39200 !important;
}

.border-primary {
  border-color: #0169B5 !important;
}

@media (min-width: 576px) {
  .border-sm-top {
    border-top: 1px solid #dee2e6;
  }
}

@media (min-width: 576px) {
  .border-sm-end {
    border-right: 1px solid #dee2e6;
  }
}

@media (min-width: 576px) {
  .border-sm-bottom {
    border-bottom: 1px solid #dee2e6;
  }
}

@media (min-width: 576px) {
  .border-sm-start {
    border-left: 1px solid #dee2e6;
  }
}

@media (min-width: 768px) {
  .border-md-top {
    border-top: 1px solid #dee2e6;
  }
}

.border-md-top-none {
  border-top: 0 !important;
}

@media (min-width: 768px) {
  .border-md-end {
    border-right: 1px solid #dee2e6;
  }
}

@media (min-width: 768px) {
  .border-md-bottom {
    border-bottom: 1px solid #dee2e6;
  }
}

@media (min-width: 768px) {
  .border-md-start {
    border-left: 1px solid #dee2e6;
  }
}

@media (min-width: 992px) {
  .border-lg-top {
    border-top: 1px solid #dee2e6;
  }
}

@media (min-width: 992px) {
  .border-lg-end {
    border-right: 1px solid #dee2e6;
  }
}

@media (min-width: 992px) {
  .border-lg-bottom {
    border-bottom: 1px solid #dee2e6;
  }
}

@media (min-width: 992px) {
  .border-lg-start {
    border-left: 1px solid #dee2e6;
  }
}

@media (min-width: 1200px) {
  .border-xl-top {
    border-top: 1px solid #dee2e6;
  }
}

@media (min-width: 1200px) {
  .border-xl-end {
    border-right: 1px solid #dee2e6;
  }
}

@media (min-width: 1200px) {
  .border-xl-bottom {
    border-bottom: 1px solid #dee2e6;
  }
}

@media (min-width: 1200px) {
  .border-xl-start {
    border-left: 1px solid #dee2e6;
  }
}

@media (min-width: 1400px) {
  .border-xxl-top {
    border-top: 1px solid #dee2e6;
  }
}

@media (min-width: 1400px) {
  .border-xxl-end {
    border-right: 1px solid #dee2e6;
  }
}

@media (min-width: 1400px) {
  .border-xxl-bottom {
    border-bottom: 1px solid #dee2e6;
  }
}

@media (min-width: 1400px) {
  .border-xxl-start {
    border-left: 1px solid #dee2e6;
  }
}

@media (min-width: 576px) {
  .border-top-sm-0 {
    border-top: 0 !important;
  }
}

@media (min-width: 768px) {
  .border-top-md-0 {
    border-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .border-top-lg-0 {
    border-top: 0 !important;
  }
}

@media (min-width: 1200px) {
  .border-top-xl-0 {
    border-top: 0 !important;
  }
}

@media (min-width: 1400px) {
  .border-top-xxl-0 {
    border-top: 0 !important;
  }
}

@media (min-width: 576px) {
  .border-end-sm-0 {
    border-right: 0 !important;
  }
}

@media (min-width: 768px) {
  .border-end-md-0 {
    border-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .border-end-lg-0 {
    border-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .border-end-xl-0 {
    border-right: 0 !important;
  }
}

@media (min-width: 1400px) {
  .border-end-xxl-0 {
    border-right: 0 !important;
  }
}

@media (min-width: 576px) {
  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }
}

@media (min-width: 768px) {
  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
}

@media (min-width: 1200px) {
  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
}

@media (min-width: 1400px) {
  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }
}

@media (min-width: 576px) {
  .border-start-sm-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 768px) {
  .border-start-md-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .border-start-lg-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .border-start-xl-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 1400px) {
  .border-start-xxl-0 {
    border-left: 0 !important;
  }
}

/* Dropmenú */
.dropdown-menu-hover-orange .dropdown-item:hover {
  background-color: #f39200;
  color: #ffffff;
  margin: auto;
}

.dropdown-menu-hover-orange .dropdown-item:hover i {
  color: #ffffff;
}

/*# sourceMappingURL=style-chl.css.map */