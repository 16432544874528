.error_container {
  display: flex;
  justify-content: center;
  flex-direction: column !important;
  padding-top: 64px;
}

.error_img_container {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.error_img {
  width: 100%; 
}

.error_title {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--ligth-gray);
  margin-bottom: 8px;
}

.error_subtitle {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.012em;
  text-align: center;
  color: var(--dark-gray);
  margin-top: 8px;
}

.error_action_container {
  display: flex;
  justify-content: center;
  margin-top: 72px !important;
}

.error_action_container button svg {
  width: 20px;
  height: 16px;
  margin-left: 8px;
}

.error_action_buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 43%;
}

.error_action_buttons.single{
  width: 100%;
  justify-content: center;
}

/* mobile */
@media screen and (min-width: 320px) and (max-width: 720px) {
  .error_action_buttons {
    flex-direction: column-reverse !important;
    width: 62%;
    justify-content: center;
    align-items: center;
  }

  .error_action_container button {
    margin-bottom: 32px !important;
  }
}