.page_title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: var(--main-blue);
  margin-top: 34px;
}

.page_title_2 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--main-blue);
  margin-bottom: 8px;
}

div.top_articles_wrapper {
  margin-top: 48px;
}

.page_subtitle {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: var(--dark-gray);
  margin-bottom: 32px;
}

.section_title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--secondary-blue);
  margin-top: 48px;
  margin-bottom: 8px;
}

.content_subtitle {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.content_paragraph {
  font-family: var(--font-family);
  color: var(--ligth-gray);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

ul.custom_list {
  margin-top: 16px !important;
}

li.custom_li {
  margin-bottom: 14px;
  margin-left: -20px;
  text-align: left;
  color: var(--dark-gray);
  line-height: 18px;
}

li.custom_li::before {
  /* content: "\20E2"; */
  content: "\25A0";
  color: var(--list-item-blue);
  display: inline-block;
  width: 12px;
  margin-left: -15px;
  font-size: 12px;
}

li.custom_li::marker {
  content: none;
}