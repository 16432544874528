.loading_wrapper {
  display: flex;
  align-items: center;
}

.custom_spinner {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 10rem;
  border: none;
  background: linear-gradient(90deg, #F3920000 0%, #F3920001 30%,#F39200 100%);
  box-shadow: none;
  animation: animate 1s linear infinite;
}

.custom_spinner::before {
  position: absolute;
  content: "";
  background: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  border-radius: 10rem;
}

.loading_text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--dark-gray);
  margin-bottom: 0px;
  margin-left: 8px;
}

@keyframes animate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.small_spinner {
  width: 28px;
  height: 28px;
}
.big_spinner {
  width: 80px;
  height: 80px;
}