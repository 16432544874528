ul.table_list_wrapper {
    box-shadow: 0px 3px 6px rgba(226, 228, 233, 0.3);
    border-radius: 8px;
    border: 0.6px solid var(--soft-gray);
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    cursor: pointer;
}

li.list_item {
    border-bottom: 1px solid var(--soft-gray);
    padding-top: 16px;
    padding-bottom: 16px;
}
li.list_item:hover {
    background-color: var(--hover-gray);
}
li.list_item:last-of-type {
    border-bottom: none;
}

button.list_item_button:hover {
     background-color: transparent;
}

button.list_item_button svg{
    color: var(--darkest-blue);
    width: 45px;
    height: 18px;
}

div.list_item_number {
    background-color: var(--background-blue);
    width: 32px;
    height: 32px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
}

.list_item_title span{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--dark-gray);
}

.table_list_container div.paginator {
  display: flex;
  margin-top: 32px;
  justify-content: center;
}
